import { confirmAlert } from 'react-confirm-alert';
import '../react-confirm-alert.css';
import { GetPaymentStatus } from '../payments/initpayments';
import { GetADoc, SetADoc, UpdateADoc } from './db';
import Progressdialog from '../components/progres';
import { mainhome, packedtoolspayment, userscollection } from './data';
import moment from 'moment';

export const Confirm = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            return (
                <div className='mx-8'>

                    <div div className='mx-auto p-8 min-w-md max-w-md text-white rounded-lg bg-gray-800 w-full m-6 lg:w-max lg:h-max' style={{ zIndex: '99999' }}>
                        <h1 className='font-bold text-lg mb-5'>{list.title}</h1>

                        <p className='mb-3'>{list.msg}</p>
                        <div className="flex gap-4 justify-end p-2">

                            <button className='font-medium' onClick={onClose}>Close</button>

                            <button
                                className='font-bold text-green-700'
                                onClick={() => {
                                    list.action();
                                    onClose();
                                }}
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    });
}

let onCloseCallback = null;

export const setOnCloseCallback = (callback) => {
    onCloseCallback = callback;
};


export const Progress = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        closeOnClickOutside: false,
        customUI: ({ title, onClose }) => {

            setOnCloseCallback(onClose())

            if (list.stats) {
                onClose()
            }
            /*  const { setOnClosePopup } = useContextProvider()
             setOnClosePopup(() => onClose) */
            return (
                <div className='mx-8' id='prog'>
                    <div div className='mx-auto p-8 min-w-md  max-w-md text-white rounded-lg bg-gray-800 w-full m-6 lg:w-max lg:h-max' style={{ zIndex: '99999' }}>
                        <div className="flex flex-col justify-center items-center lis-center">
                            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full mb-3" role="status">                            </div>
                            <p className='text-lg text-center mb-5'>{list.msg}</p>
                        </div>
                        <p className='mb-3'>{list.message}</p>
                    </div>
                </div>
            );
        }
    })
}
export const Info = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        customUI: ({ title, onClose }) => {
            return (
                <div className='mx-8'>
                    <div className='mx-auto p-8 min-w-md  max-w-md text-white rounded-lg bg-gray-800 w-full m-6 lg:w-max lg:h-max' style={{ zIndex: '99999' }}>
                        <h1 className='font-bold text-lg mb-5'>{list.title}</h1>

                        <p className='mb-3'>{list.msg}</p>
                        <div className="flex gap-4 justify-end p-2">
                            <button className='font-medium' onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            );
        }
    });
}
export const ImagePreview = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        closeOnClickOutside: true,
        closeOnEscape: true,
        customUI: ({ title, onClose }) => {
            return (
                <div className='mx-8 w-full m-8'>
                    <div className='mx-auto p-8 max-w-full text-white rounded-lg bg-gray-800 w-full m-6 lg:w-max lg:h-max' style={{ zIndex: '99999' }}>
                        <h1 className='font-bold text-lg mb-5'>{list.title}</h1>
                        <img className='w-96' src={list.img} alt={list.title} />


                    </div>
                </div>
            );
        }
    });
}

export const onClosePopup = () => onCloseCallback

export const PaymentAPI = async (amt) => {

    const url = 'https://api.commerce.coinbase.com/charges';
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'X-CC-Version': process.env.REACT_APP_VERSION,
            'X-CC-Api-KEY': process.env.REACT_APP_PAY,
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            local_price: { amount: amt, currency: 'USD' },
            metadata: { customer_id: 'skldfasdlfaf', customer_name: 'afdfadsf' },
            name: 'PACKEDTOOLS',
            description: 'Topup your Crypto transaction receipt generator account balance in able to make purchase',
            pricing_type: 'fixed_price'
        })
    };

    return await fetch(url, options)

}
export const CheckPaymentAPI = async (code) => {

    const url = 'https://api.commerce.coinbase.com/charges/' + code;
    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            'X-CC-Version': process.env.REACT_APP_VERSION,
            'X-CC-Api-KEY': process.env.REACT_APP_PAY,
            'content-type': 'application/json'
        }
    };

    return await fetch(url, options)

}

export const ConversionRate = async (currency) => {
    let headersList = {
        "Accept": "*/*",
        "User-Agent": "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Mobile Safari/537.36"
    }

    let response = await fetch("https://us-central1-demuvees.cloudfunctions.net/api/rate?currency=" + currency, {
        method: "GET",
        headers: headersList
    });

    const res = await response.text();
    return res
}


export const GetIPAddress = async () => {
    let headersList = {
        "Accept": "*/*",
        "User-Agent": "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Mobile Safari/537.36"
    }

    let response = await fetch("https://id.hadron.ad.gt/v1/hadron.json?_it=prem", {
        method: "GET",
        headers: headersList
    });

    const input = await response.json();
    return await GeoLocation(input.addr)

}

export const GeoLocation = async (ip) => {

    let headersList = {
        "authority": "ipinfo.io",
        "accept": "*/*",
        "accept-language": "en-GB,en;q=0.9,ak-GH;q=0.8,ak;q=0.7,en-US;q=0.6",
        "content-type": "application/json",
        "referer": "https://ipinfo.io/"
    }

    let response = await fetch("https://ipinfo.io/widget/demo/" + ip, {
        method: "GET",
        headers: headersList
    });
    const r = await response.json()

    // return await response.json();
    // console.log(r)

    const result = await GetCurrency(r)
    return result

    /* let response = await fetch("https://us-central1-demuvees.cloudfunctions.net/api/locationinfo?ip=" + ip)
    const json = await response.json()
    const data = json.location.data

    return await data */
}
export const GetCurrency = async (data) => {

    let headersList = {
        "Accept": "*/*",
        "User-Agent": "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Mobile Safari/537.36"
    }

    let response = await fetch("https://restcountries.com/v3.1/alpha/" + data.data.country, {
        method: "GET",
        headers: headersList
    });

    const res = await response.json();
    const currency = Object.keys(res[0].currencies)[0]
    const country = res[0].currencies[Object.keys(res[0].currencies)[0]].name

    data['currency'] = currency
    data['countryname'] = country
    return data

}

export const waitawhile = async (content, delay) => {
    await new Promise((resolve) => {
        content()
        setTimeout(() => {
            resolve()
        }, delay);

    })
}

export const ReceiveEmail = async (e, api) => {

    const payload1 = {
        "from": e.send.from,
        "to": e.send.to,
        "content": e.send.data,
        "subject": e.send.subject,
        "email": e.send.email
    }
    //    console.log(payload1)
    const options1 = {
        method: 'POST',
        headers: {
            "Accept": "*/*",
            'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/97.0.4692.71 Safari/537.36',
            'Content-Type': 'application/json',

        },
        body: JSON.stringify(payload1)
    }
    return await fetch("https://us-central1-demuvees.cloudfunctions.net/api/emailnotification", options1)

}
export const SendEmail = async (e, api) => {

    const payload2 = {
        "from": e.recieve.from,
        "to": e.recieve.to,
        "content": e.recieve.data,
        "subject": e.recieve.subject,
        "email": e.recieve.email
    }
    // console.log(payload2)
    const options2 = {
        method: 'POST',
        headers: {
            "Accept": "*/*",
            'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/97.0.4692.71 Safari/537.36',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload2)
    }

    return await fetch("https://us-central1-demuvees.cloudfunctions.net/api/emailnotification", options2)

}


const EmailVerification = async (list) => {

    const { from, to, subject, content } = list;

    const MJ_APIKEY_PUBLIC = process.env.REACT_APP_MJ_APIKEY_PUBLIC;
    const MJ_APIKEY_PRIVATE = process.env.REACT_APP_MJ_APIKEY_PRIVATE;

    const emailData = {
        "Messages": [
            {
                "From": {
                    "Email": process.env.REACT_APP_REACT_APP_EMAIL_USERNAME,
                    "Name": from
                },
                "To": [
                    {
                        "Email": to,
                        "Name": "User"

                    }
                ],
                "Subject": subject,
                //"TextPart": "Dear passenger 1, welcome to Mailjet! May the delivery force be with you!",
                "HTMLPart": content
            }
        ]
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${MJ_APIKEY_PUBLIC}:${MJ_APIKEY_PRIVATE}`)}`
        },
        body: JSON.stringify(emailData)
    };

    return await fetch('https://api.mailjet.com/v3.1/send', requestOptions)
        .then(res => res.json())
        .then(data => {
            if (data["Status"] === "error") {
                return { status: "error", message: data.Errors };
            } else {
                return { status: "success", message: 'Congratulations!!!' };

            }
        })
        .catch(error => {
            return { status: "error", message: "An unknown error occured. Try Again! " + error };
        });

}


export const ReceiveEmail2 = async (e) => {
    var receipient = {
        "personalizations": [
            {
                "to": [
                    {
                        "email": `${e.send.to}`
                    }
                ],
                "subject": `${e.send.subject}`
            }
        ],
        "from": {
            "email": `${e.send.from} <${e.send.email}>`
        },
        "content": [
            {
                "type": "text/html",
                "value": `${e.send.data}`
            }
        ]
    }


    const options1 = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'X-RapidAPI-Key': process.env.REACT_APP_EMAIL_API_2,
            'X-RapidAPI-Host': 'rapidprod-sendgrid-v1.p.rapidapi.com'
        },
        body: JSON.stringify(receipient)
    };

    await fetch("https://rapidprod-sendgrid-v1.p.rapidapi.com/mail/send", options1).then(v => v.json()).then((v) => console.log("sent")).catch((err) => console.log(err.message))

}
export const SendEmail2 = async (e) => {

    var sender = {
        "personalizations": [
            {
                "to": [
                    {
                        "email": `${e.recieve.to}`
                    }
                ],
                "subject": `${e.recieve.subject}`
            }
        ],
        "from": {
            "email": `${e.recieve.from} <${e.recieve.email}>`
        },
        "content": [
            {
                "type": "text/html",
                "value": `${e.recieve.data}`
            }
        ]
    }

    const options2 = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'X-RapidAPI-Key': process.env.REACT_APP_EMAIL_API_2,
            'X-RapidAPI-Host': 'rapidprod-sendgrid-v1.p.rapidapi.com'
        },
        body: JSON.stringify(sender)
    }

    await fetch("https://rapidprod-sendgrid-v1.p.rapidapi.com/mail/send", options2).then(v => v.json()).then((v) => {
        console.log("sent")
        Info({ title: "Success", msg: "Transaction completed successfully" })
    }
    ).catch((err) => console.log(err.message))

}

export const TXS = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export const FXS = (length) => {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const Moments = (date) => {
    if (typeof date !== 'object') {
        date = new Date(date);
    }

    var seconds = Math.floor((new Date() - date) / 1000);
    var intervalType;

    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        intervalType = 'year';
    } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            intervalType = 'month';
        } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                intervalType = 'day';
            } else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    intervalType = "hour";
                } else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        intervalType = "minute";
                    } else {
                        interval = seconds;
                        intervalType = "second";
                    }
                }
            }
        }
    }

    if (interval > 1 || interval === 0) {
        intervalType += 's ago';
    } else {
        intervalType += ' ago';
    }

    return interval + ' ' + intervalType;
};


export const ExchangeRage = async () => {
    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': '1080ba1908msh027057859d83ed0p13ac36jsn0ac1373f9e2d',
            'X-RapidAPI-Host': 'coingecko.p.rapidapi.com',
            "User-Agent": "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Mobile Safari/537.36"
        }
        /* "headers": {
           "content-type": "text/plain",
           "sec-ch-ua": "\"Chromium\";v=\"124\", \"Google Chrome\";v=\"124\", \"Not-A.Brand\";v=\"99\"",
           "sec-ch-ua-mobile": "?0",
           "sec-ch-ua-platform": "\"Windows\""
       },
       "referrer": "https://www.coingecko.com/",
       "referrerPolicy": "strict-origin-when-cross-origin",
       "body": null,
       "method": "GET",
       "mode": "cors",
       "credentials": "omit"*/
    };

    //  return await fetch('https://cdn.jsdelivr.net/gh/prebid/currency-file@1/latest.json', options)
    return await fetch('https://coingecko.p.rapidapi.com/exchange_rates', options)


}

export const GenRandom = (len, array) => {
    const list = []
    for (var i = 0; i < len; i++) {
        list.push(array[Math.floor(Math.random() * array.length)]);
    }
    return list
}


export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}



export const UID = () => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 30; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}


export const trxx = async () => {
    return await fetch('https://blockchain.info/unconfirmed-transactions?format=json');
}


export const GetTRXID = async (arr, rate) => {
    return await arr.filter((v) => ((v.inputs[0].prev_out.value / 100000000) * rate) === 45875)
}

export const EllapsedDate = (date) => {
    var now = new Date();
    var targetDate = new Date(date);
    var timeDiff = now.getTime() - targetDate.getTime();
    var diffDate = new Date(timeDiff);
    var diffYears = diffDate.getUTCFullYear() - 1970;
    var diffMonths = diffDate.getUTCMonth();
    var diffDays = diffDate.getUTCDate() - 1;
    var diffHours = diffDate.getUTCHours();
    var diffMinutes = diffDate.getUTCMinutes();
    var diffSeconds = diffDate.getUTCSeconds();
    var formattedDiff = diffYears + " years, " + diffMonths + " months, " + diffDays + " days, " +
        diffHours + " hours, " + diffMinutes + " minutes, " + diffSeconds + " seconds";

    //console.log(date)
    //console.log(formattedDiff)
    if (diffDays < 1) {
        return true
    }
    else {
        return false
    }
}

export async function getGoogleTime() {
    try {
        const response = await fetch('https://jsonplaceholder.typicode.com/posts/1'); // Replace with Google's Time API endpoint
        if (!response.ok) {
            throw new Error('Failed to fetch time');
        }
        const data = await response.json();
        const currentTime = data.time; // Adjust this to match the structure of Google's Time API response
        const formattedTime = moment(currentTime).format('LLL'); // Format the time using Moment.js
        return formattedTime;
    } catch (error) {
        console.error('Error fetching time:', error);
        return null;
    }
}

export const getRandomElements = (array, count) => {
    const randomIndexes = [];
    const randomElements = [];

    while (randomIndexes.length < count) {
        const randomIndex = Math.floor(Math.random() * array.length);
        if (!randomIndexes.includes(randomIndex)) {
            randomIndexes.push(randomIndex);
        }
    }

    randomIndexes.forEach(index => {
        randomElements.push(array[index]);
    });

    return randomElements;
}

export const executeProcess = async (processes) => {

    return new Promise((resolve) => {
        //console.log(`Executing: ${process.info}`);
        /*  const interval = setInterval(() => {
         }, 5000) */
        setTimeout(() => {
            resolve({ pro: processes.key, dur: processes.process });
            //clearInterval(interval)
        }, processes.process);
    });
};

export const savePaymentCodes = (email, code) => {
    const paymentcode = {
        email,
        code
    }

    const codes = JSON.parse(localStorage.getItem(email))
    if (codes !== null) {
        codes.push(code)
        const saveifnotnull = JSON.stringify(paymentcode)
        localStorage.setItem(email, saveifnotnull)
    } else {
        const saveifnull = [code]
        localStorage.setItem(email, JSON.stringify(saveifnull))
    }

}
export const retrievePaymentCodes = async (email) => {
    const codes = JSON.parse(localStorage.getItem(email))
    if (codes !== null && codes.length > 0) {
        for (const [index, singlecode] of codes.entries()) {
            await GetPaymentStatus(singlecode)
                .then((v) => v.text())
                .then((value) => {
                    const res = JSON.parse(value)[0];
                    console.log(index, res)

                    /*  if (status === "waiting") {
        return (
            <div className="flex gap-2">
                <h1 className="text-green-500">{status}</h1>
                <div
                    className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    if (status === "confirmed") {

        return (
            <div className="flex gap-2">
                <h1 className="text-green-100">{status}</h1>
                <div
                    className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    if (status === "finished") {

        return (
            <div className="flex gap-2">
                <h1 className="text-green-800">{status}</h1>
                <FaCheckCircle className="text-green-800" size={25} />
            </div>
        );
    }
    if (status === "failed") {
        return (
            <div className="flex gap-2">
                <h1 className="text-red-100">{status}</h1>
                <FaStopCircle className="text-red-100" size={25} />
            </div>
        );
    }
    if (status === "expired") {
        return (
            <div className="flex gap-2">
                <h1 className="text-red-900">{status}</h1>
                <FaStopCircle className="text-red-900" size={25} />
            </div>
        );
    }
    if (status === "confirming") {
        return (
            <div className="flex gap-2">
                <h1 className="text-orange-600">{status}</h1>
                <FaEllipsisH className="text-orange-600" size={25} />
            </div>
        );
    }
    if (status === "sending") {
        return (
            <div className="flex gap-2">
                <h1 className="text-green-600">{status}</h1>
                <FaEllipsisH className="text-green-600" size={25} />
            </div>
        );
    }
    if (status === "partially_paid") {


        return (
            <div className="flex gap-2">
                <h1 className="text-red-900">
                    {"Payment not fully made $"} {amount} only
                </h1>
                <FaQuestionCircle className="text-red-900" size={25} />
            </div>
        );
    } */
                })
                .catch((err) => {

                });
        }
    }
}

export const updatepayment = (data, payment, cuid, navigation) => {
    var balance = 0;
    const uid = UID()
    GetADoc(userscollection, uid).then(async (value) => {
        if (payment.outcome_amount !== null) {
            balance = parseInt(
                value.data().balance + payment.outcome_amount
            );
            Progressdialog({
                msg: "Updating your account balance. Please do not close this window.",
            });
            await UpdateADoc(userscollection, cuid, { balance });

            await SetADoc(packedtoolspayment, cuid, {
                item: 'Account top up',
                type: "An amount of $" + payment.outcome_amount.toFixed(2) + 'was added to your account balance',
                paymentid: data,
                purchasedat: moment().format("LLL"),
                uid: cuid,
                docid: uid,
                amount: payment.outcome_amount.toFixed(2)
            });
            const update = [
                {
                    /* invitationcode: InitUser().invitationcode,
                    createdat: InitUser().createdat,
                    username: InitUser().username,
                    invitedby: InitUser().invitedby,
                    balance,
                    password: InitUser().password,
                    email: InitUser().email,
                    uid: cuid, */
                },
            ];

            localStorage.setItem(
                "user",
                JSON.stringify(update)
            );
            Info({
                title: "Account updated",
                msg:
                    "Your account balance has been topped up with $" +
                    parseInt(payment.outcome_amount.toFixed(2)) +
                    " continue to make transactions with us. Enjoy!",
            });
            navigation(mainhome, { replace: true });
            return;
        }
    });

}