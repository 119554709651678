
import React, { useState } from 'react';
import { CreditCardIcon } from '@heroicons/react/outline'

export const BankPaymentGateway = () => {
    return (
        <div className='flex flex-col p-4'>
            <p className='text-white font-extrabold text-3xl md:text-4xl lg:text-4xl uppercase mt-8'>  Pay with Bank Services  </p>

            <CreditCardForm />
        </div>
    )
}




const CreditCardForm = () => {
    const [cardNumber, setCardNumber] = useState('');
    const [cardType, setCardType] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');

    const handleCardNumberChange = (e) => {
        let formattedCardNumber = e.target.value.replace(/\s/g, '');
        formattedCardNumber = formattedCardNumber.replace(/\D/g, '')
        if (formattedCardNumber.length <= 16) {
            formattedCardNumber = formattedCardNumber.replace(/(.{4})/g, '$1 ').trim();
            setCardNumber(formattedCardNumber);

            // Detect card type
            if (/^4/.test(formattedCardNumber)) {
                setCardType('visa');
            } else if (/^5[1-5]/.test(formattedCardNumber)) {
                setCardType('mastercard');
            } else {
                setCardType('');
            }
        }
    };

    const handleExpiryChange = (e) => {
        let formattedExpiry = e.target.value.replace(/\s/g, ''); // Remove whitespace
        formattedExpiry = formattedExpiry.replace(/\D/g, ''); // Remove non-numeric characters
        if (formattedExpiry.length <= 4) {
            // Add zero to the start if the month is a single digit
            let int = setTimeout(() => {
                if (formattedExpiry.length === 1) {
                    formattedExpiry = `0${formattedExpiry}`;
                    setExpiry(formattedExpiry);
                }
            }, 2000)
            // Check if the month is between 01 and 12
            if (formattedExpiry.length >= 2) {
                const month = parseInt(formattedExpiry.substring(0, 2));
                if (month < 1 || month > 12) {
                    return; // Invalid month, do not update state
                }
            }
            // Check if the year is current year or above
            if (formattedExpiry.length === 4) {
                const currentYear = new Date().getFullYear();
                const year = parseInt(formattedExpiry.substring(2, 4));
                if (year < currentYear % 100) {
                    return; // Year is in the past, do not update state
                }
            }
            formattedExpiry = formattedExpiry.replace(/^(.{2})/g, '$1/').trim();
            setExpiry(formattedExpiry);
        }
    };


    const handleCvvChange = (e) => {
        let formattedCvv = e.target.value.replace(/\D/g, '');
        if (formattedCvv.length <= 3) {
            setCvv(formattedCvv);
        }
    };

    return (
        <div className="max-w-md mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
            <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-semibold">Credit Card Payment</h2>
                {cardType && <CreditCardIcon className="w-8 h-8 text-gray-600" />}
            </div>
            <form>
                <div className="mb-4">
                    <label htmlFor="cardNumber" className="block text-gray-700 font-semibold mb-1">Card Number</label>
                    <input
                        type="text"
                        id="cardNumber"
                        value={cardNumber}
                        onChange={handleCardNumberChange}
                        className="form-input w-full rounded-lg px-4 py-2"
                        placeholder="XXXX XXXX XXXX XXXX"
                        maxLength="19"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="expiry" className="block text-gray-700 font-semibold mb-1">Expiry (MM/YY)</label>
                    <input
                        type="text"
                        id="expiry"
                        value={expiry}
                        onChange={handleExpiryChange}
                        className="form-input w-full rounded-lg px-4 py-2"
                        placeholder="MM/YY"
                        maxLength="5"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="cvv" className="block text-gray-700 font-semibold mb-1">CVV</label>
                    <input
                        type="text"
                        id="cvv"
                        value={cvv}
                        onChange={handleCvvChange}
                        className="form-input w-full rounded-lg px-4 py-2"
                        placeholder="XXX"
                        maxLength="3"
                        required
                    />
                </div>
                <button type="submit" className="bg-blue-500 text-white font-semibold rounded-lg px-6 py-3 w-full">
                    Pay Now
                </button>
            </form>
        </div>
    );
};

