class DialogManager {
    constructor() {
      this.onClose = null;
    }
  
    setOnClose(onClose) {
      this.onClose = onClose;
    }
  
    closeDialog() {
      if (this.onClose) {
        this.onClose();
      }
    }
  }
  
  const instance = new DialogManager();
  export default instance;
  