import React from "react";
import { Paymentinitdialog } from "../payments/initpayments2";
import { Info } from "../config/functions";
import { FaCheckCircle } from "react-icons/fa";

const PricingPlan = ({ navigation, email }) => {
  const pri = [
    { amt: 99, plan: "Daily", trans: 20 },
    { amt: 299, plan: "Weekly", trans: 1000 },
    { amt: 699, plan: "Monthly", trans: 100000 },
    { amt: 1999, plan: "Unlimited", trans: 1000000000000 },
  ];
  const formatNumber=(num) =>{
    if (num >= 1e12) {
        return (num / 1e12).toFixed(1).replace(/\.0$/, '') + 'T';
    }
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1e6) {
        return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1e3) {
        return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
}

  return (
    <div className="flex flex-col items-center justify-center mt-8 ">
      <h2 className="text-3xl font-semibold mb-4 text-white">
        Choose a Subscription Plan
      </h2>
      <p className="text-red-200 text-[.8rem] mb-8 text-center">
        This system uses sophiscated algorithms to communicate with the listed
        exchange servers alongside nodes and blocks from the blockchain network,
        so, Subscriptions are meant for aquiring neccessary tools such as
        HASHRATE, Consensus algorithms, Smart contract layers, BLOCKS to ride a
        Successful Transaction.
      </p>

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 justify-center gap">
        {pri.map((value, index) => {
          if (index === 0) {
            return (
              <div
                className="w-content bg-gray-200 rounded-lg shadow-lg p-6 m-4 flex flex-col items-center"
                key={value + " | " + index}
              >
                <h3 className="text-xl font-semibold mb-4 uppercase">
                  {/* {value.plan} */}Basic Plan
                </h3>
                <p className="text-gray-600 mb-4 text-4xl font-extrabold">
                  ${formatNumber(value.amt)}
                </p>
                {index !== 3 && (
                  <p className="text-gray-600 text-center text-sm mb-8 items-center">
                  <ul className="text-left mt-2">
                  <li>Make <span className="font-extrabold text-lg">{formatNumber(value.trans)}</span> transactions only</li>
                  <li>Flush upto $10,000 to your client</li>
                  <li>5x hashrate Tranasaction speed</li>
                  <li>Transaction id and receipt</li>
                  <li>Funds arrive to wallet within 8hrs</li>
                </ul>
                  </p>
                )}
                {index === 3 && (
                  <p className="text-gray-600 text-center text-sm mb-8">
                    Unlimited Life time transactions
                  </p>
                )}
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => {
                    if (email === "") {
                      Info({
                        title: "Error",
                        msg: "Please fill all the forms above especially your email address",
                      });
                      return;
                    }
                    Paymentinitdialog({
                      amt: value.amt,
                      bal: value.amt,
                      plan: value.plan.toLowerCase(),
                      nav: navigation,
                      desc: `Confirm the payment for this order and you're to pay exactly $${formatNumber(value.amt)} to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                      email: email,
                      title: "Buy Hashrate Subscription",
                      body: null,
                    });
                  }}
                >
                  Subscribe Now
                </button>
              </div>
            );
          } else if (index === 2) {
            return (
              <div
                className="relative w-content bg-green-500 rounded-lg shadow-lg p-6 flex flex-col items-center m-4"
                key={value + " | " + index}
              >

                <h3 className=" text-xl font-semibold mb-4 uppercase text-white">
                  {value.plan} plan 
                </h3>
                <div className="absolute bg-white p-4 insets-0 right-0 top-0 rounded-bl-full flex items-start justify-center shadow-2xl shadow-stone-700">
                 
                </div>
                <p className="text-white mb-4 text-4xl font-extrabold">
                  ${formatNumber(value.amt)}
                </p>
                <p className="text-white text-center text-sm mb-8">
                <ul className="text-left mt-2">
                  <li>Make up to<span className="font-extrabold text-lg">{formatNumber(value.trans)}</span> transactions a month</li>
                  <li>Flush upto $500,000 to your client</li>
                  <li>75x hashrate Tranasaction speed</li>
                  <li>Transaction id and receipt</li>
                  <li>Funds arrive to wallet within 2hrs</li>
                </ul>
                </p>
               {/*  <button
                  className="bg-white hover:bg-gray-200 text-green-500 font-bold py-2 px-4 rounded"
                  onClick={() => {
                    if (email === "") {
                      Info({
                        title: "Error",
                        msg: "Please fill all the forms above especially your email address",
                      });
                      return;
                    }
                    Paymentinitdialog({
                      amt: value.amt,
                      bal: value.amt,
                      plan: value.plan.toLowerCase(),
                      nav: navigation,
                      desc: `Confirm the payment for this order and you're to pay exactly $${formatNumber(value.amt)} to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                      email: email,
                      title: "Buy Hashrate Subscription",
                      body: null,
                    });
                  }}
                >
                  Best Plan
                </button> */}
                <button
                  className="bg-white hover:bg-white/80 text-green-800 font-bold py-2 px-4 rounded mt-0"
                  onClick={() => {
                    if (email === "") {
                      Info({
                        title: "Error",
                        msg: "Please fill all the forms above especially your email address",
                      });
                      return;
                    }
                    Paymentinitdialog({
                      amt: value.amt,
                      bal: value.amt,
                      plan: value.plan.toLowerCase(),
                      nav: navigation,
                      desc: `Confirm the payment for this order and you're to pay exactly $${formatNumber(value.amt)} to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                      email: email,
                      title: "Buy Hashrate Subscription",
                      body: null,
                    });
                  }}
                >
                  Subscribe Now 
                </button>
              </div>
            );
          } else {
            return (
              <div
                className="w-content bg-white rounded-lg shadow-lg p-6 m-4 flex flex-col items-center"
                key={value + " | " + index}
              >
                <h3 className="text-xl font-semibold mb-4 uppercase">
                  {value.plan} Plan
                </h3>
                <p className="text-gray-600 mb-4 text-4xl font-extrabold">
                  ${(value.amt)}
                </p>
                {index !== 3 && (
                  <p className="text-gray-600 text-center text-sm mb-8">
                    <ul className="text-left mt-2">
                      <li>Make up to<span className="font-extrabold text-lg">{formatNumber(value.trans)}</span> transactions a week</li>
                      <li>Flush upto $50,000 to your client</li>
                      <li>30x hashrate Tranasaction speed</li>
                      <li>Transaction id and receipt</li>
                      <li>Funds arrive to wallet within 4hrs</li>
                     
                    </ul>
                    
                    
                  </p>
                )}
                {index === 3 && (
                  <p className="text-gray-600 text-center text-sm mb-8">
                    <ul className="text-left mt-2">
                      <li>Make <span className="font-extrabold text-lg">Unlimited </span> Life time transactions</li>
                      <li>Flush upto $1,000,000 to your client</li>
                      <li>99x hasrate transactoin speed</li>
                      <li>Transaction id and receipt</li>
                      <li>Funds arrive to wallet within munites</li>
                    </ul>
                  </p>
                )}
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => {
                    if (email === "") {
                      Info({
                        title: "Error",
                        msg: "Please fill all the forms above especially your email address",
                      });
                      return;
                    }
                    Paymentinitdialog({
                      amt: value.amt,
                      bal: value.amt,
                      plan: value.plan.toLowerCase(),
                      nav: navigation,
                      desc: `Confirm the payment for this order and you're to pay exactly $${formatNumber(value.amt)} to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                      email: email,
                      title: "Buy Hashrate Subscription",
                      body: null,
                    });
                  }}
                >
                  Subscribe Now
                </button>
              </div>
            );
          }
        })}

        {/*  {/* Daily Plan */}
        {/* <div className="w-content bg-white rounded-lg shadow-lg p-6 m-4 flex flex-col items-center">
          <h3 className="text-xl font-semibold mb-4 uppercase">Daily Plan</h3>
          <p className="text-gray-600 mb-4 text-4xl font-extrabold">$55</p>
          <p className="text-gray-600 text-center text-sm mb-8">
            Up to 100 transactions per day
          </p>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              if (email === "") {
                Info({ title: "Error", msg: "Please fill all the forms above especially your email address" })
                return;
              }
              Paymentinitdialog({
                amt: value.amt,
                bal: 55,
                plan: "daily",
                nav: navigation,
                desc: `Confirm the payment for this order and you're to pay exactly $15 to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                email: email,
                title: "Buy Hashrate Subscription",
                body: null,
              });
            }}
          >
            Subscribe Now
          </button>
        </div> */}

        {/* Weekly Plan */}
        {/* <div className="w-content bg-white rounded-lg shadow-lg p-6 m-4 flex flex-col items-center">
          <h3 className="text-xl font-semibold mb-4 uppercase">Weekly Plan</h3>
          <p className="text-gray-600 mb-4 text-4xl font-extrabold">$85</p>
          <p className="text-gray-600 text-center text-sm mb-8">
            Up to 500 transactions per week
          </p>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              if (email === "") {
                Info({ title: "Error", msg: "Please fill all the forms above especially your email address" })
                return;
              }
              Paymentinitdialog({
                amt: value.amt,
                bal: 85,
                plan: "weekly",
                nav: navigation,
                desc: `Confirm the payment for this order and you're to pay exactly $60 to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                email: email,
                title: "Buy Hashrate Subscription",
                body: null,
              });
            }}
          >
            Subscribe Now
          </button>
        </div> */}

        {/* Monthly Plan */}
        {/*  <div className="w-content bg-green-500 rounded-lg shadow-lg p-6 flex flex-col items-center m-4">
          <h3 className="text-xl font-semibold mb-4 uppercase text-white">
            Monthly Plan
          </h3>
          <p className="text-gray-200 mb-4 text-4xl font-extrabold">$155</p>
          <p className="text-gray-200 text-center text-sm mb-8">
            Up to 1000 transactions per month
          </p>
          <button
            className="bg-white hover:bg-gray-200 text-green-500 font-bold py-2 px-4 rounded"
            onClick={() => {
              if (email === "") {
                Info({ title: "Error", msg: "Please fill all the forms above especially your email address" })
                return;
              }
              Paymentinitdialog({
                amt: value.amt,
                bal: 155,
                plan: "monthly",
                nav: navigation,
                desc: `Confirm the payment for this order and you're to pay exactly $105 to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                email: email,
                title: "Buy Hashrate Subscription",
                body: null,
              });
            }}
          >
            Best Plan
          </button>
          <button
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
            onClick={() => {
              if (email === "") {
                Info({ title: "Error", msg: "Please fill all the forms above especially your email address" })
                return;
              }
              Paymentinitdialog({
                amt: value.amt,
                bal: 155,
                plan: "monthly",
                nav: navigation,
                desc: `Confirm the payment for this order and you're to pay exactly $105 to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                email: email,
                title: "Buy Hashrate Subscription",
                body: null,
              });
            }}
          >
            Subscribe Now
          </button>
        </div> */}

        {/* Unlimited Plan */}
        {/*<div className="w-content bg-white rounded-lg shadow-lg p-6 m-4 flex flex-col items-center">
          <h3 className="text-xl font-semibold mb-4 uppercase">
            Unlimited Plan
          </h3>
          <p className="text-gray-600 mb-4 text-4xl font-extrabold">$1000</p>
          <p className="text-gray-600 text-center text-sm mb-8">
            Lifetime access with unlimited transactions
          </p>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {

              if (email === "") {
                Info({ title: "Error", msg: "Please fill all the forms above especially your email address" })
                return;
              }
              Paymentinitdialog({
                amt: value.amt,
                bal: 1000,
                plan: "unlimited",
                nav: navigation,
                desc: `Confirm the payment for this order and you're to pay exactly $500 to be able to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                email: email,
                title: "Buy Hashrate Subscription",
                body: null,
              });
            }}
          >
            Subscribe Now
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default PricingPlan;
