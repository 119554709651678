import moment from "moment";
import { GetADoc } from "./db";
import { Info, getGoogleTime } from "./functions"



export const FormEvent = async (e, navigation) => {
    var status = false;

    if (e.usd === '') {
        Info({ title: "Error", msg: "Enter the amount you wish to send in US Dollars ($)" })
        e.usdref.current.focus();
        status = false;
        return;
    }
    if (parseInt(e.usd) < 100) {
        Info({ title: "Error", msg: "Enter the amount from $100 upwards" })
        e.usdref.current.focus();
        status = false;
        return;
    }
    if (e.amount === '') {
        Info({ title: "Error", msg: "Enter the amount you wish to send" })
        e.amountref.current.focus();
        status = false;
        return;
    }
    if (e.crypto === '') {
        Info({ title: "Error", msg: "Please setect cryptocurrency" })
        e.cryptoref.current.focus();
        status = false;
        return;
    }
    if (e.address === '') {
        Info({ title: "Error", msg: "Enter the cryptocurrency wallet address" })
        e.addressref.current.focus();
        status = false;
        return;
    }
    if (e.exchange === '') {
        Info({ title: "Error", msg: "Please select an exchange" })
        e.exchangeref.current.focus();
        status = false;
        return;
    }
    if (e.emailin === '') {
        Info({ title: "Error", msg: "Enter your email address" })
        e.emailinref.current.focus();
        status = false;
        return;
    }
    if (e.emailout === '') {
        Info({ title: "Error", msg: "Enter receipient's email address" })
        e.emailoutref.current.focus();
        status = false;
        return;
    }
    await GetADoc('transactionreceipt', e.emailin).then(async (v) => {
        if (v.exists) {
            console.log(v.data().date);
            const date = v.data().date
            return await getGoogleTime()
                .then(time => {
                    if (time) {
                       // console.log('google time: ', time)
                        const subdate = moment(date)
                        const currentdate = moment(time)

                        const diff = currentdate.diff(subdate, 'days')


                        if (v.data().plan === 'daily') {
                            //console.log('day difference 1: ', diff)
                            if ((diff < 1) && (v.data().counter < 100) && (v.data().amount > 0)) {
                                status = true
                                return
                            } else {
                                Info({ title: "Subscription Due", msg: `Your ${v.data().plan} subscription plan has expired. Scroll below to re-subscribe or choose another subscription plan.` })
                                status = false
                            }
                            return;
                        }

                        if (v.data().plan === 'weekly') {
                            //console.log('day difference 2: ', diff)
                            if ((diff < 8) && (v.data().counter < 500) && (v.data().amount > 0)) {
                                status = true
                            } else {
                                Info({ title: "Subscription Due", msg: `Your ${v.data().plan} subscription plan has expired. Scroll below to re-subscribe or choose another subscription plan.` })
                                status = false;
                            }
                            return

                        }

                        if (v.data().plan === 'monthly') {
                           // console.log('day difference 3: ', diff)
                            if ((diff < 31) && (v.data().counter < 1500) && (v.data().amount > 0)) {
                                Info({ title: "Subscription Due", msg: `Your ${v.data().plan} subscription plan has expired. Scroll below to re-subscribe or choose another subscription plan.` })
                                status = true
                            } else {
                                status = false;
                            }
                            return;
                        }

                        if (v.data().plan === 'unlimited') {
                            //console.log('day difference 4: ', diff)
                            status = true;
                            return;
                        }


                    } else {
                        console.log('Failed to fetch from Google');
                    }
                });


            /*  if (typeof (v.data()) === "undefined" || v.data().balance < 1) {
                 Confirm({
                     title: "Error", msg: `This email adddress ${e.emailin} does not have any hashrate in it to generate the receipt. You need ${packedtoolsamount * 1000000}Hertz ($${packedtoolsamount}) hasrate to complete this process. You currently have 0GHz hashrate. Topup your hashrate`, action: () => {
                         setTimeout(() => {
                             Paymentinitdialog({
                                 amt: null,
                                 bal: packedtoolsamount,
                                 nav: navigation,
                                 desc: `Confirm the payment for this order and you're to pay exactly ${packedtoolsamount} to get you  ${packedtoolsamount * 1000000} Hertz Hashrate to be able to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                                 email: e.emailin,
                                 title: "Buy Hashrate",
                                 body: null,
                             })
                         }, 100);
                     }
                 })
             
                 status = false;
                 return;
             }
             if (v.data().balance > 0 && v.data().balance < packedtoolsamount) {
                 Confirm({
                     title: "Low on Balance", msg: `Your Balance is too low. You currently have $${v.data().balance} approximately ${v.data().balance * 1000000} hashrate but you need at least ${packedtoolsamount}000000 Hasrate to continue. Do you wish to topup your balance with $${packedtoolsamount - (v.data().balance)} now?`, action: () => {
                         setTimeout(() => {
                             Paymentinitdialog({
                                 amt: null,
                                 bal: (packedtoolsamount - (v.data().balance)),
                                 nav: navigation,
                                 desc: `Confirm the payment for this order and you're to pay exactly $${packedtoolsamount - (v.data().balance)} to topup your balance with any of the currencies below. Continue with this transaction?`,
                                 email: e.emailin,
                                 title: "Top up",
                                 body: null,
                             })
                         }, 100);
                     }
                 })
                 status = false
                 return;
             }
             else {
                 Info({ title: "Success", msg: "All process checked" })
             
                 status = true
                 return;
             } */

        } else {
            Info({ title: "Error", msg: "No subscription plan found for this address" })
            status = false
            return;

        }
    }).catch((e) => {
        Info({ title: "Subscription", msg: `Your account is not subscribed on any active plan yet. Scroll below choose a suitable subscription plan.` })
        status = false;
        return;
    })

    return status//{ price: e.usd, exchange: e.exchange, amt: e.amount, symbol: e.crypto, address: e.address, in: e.emailin, out: e.emailout }

}

/*  Confirm({
                         title: "Subscription", msg: `Your ${v.data().plan} subscription plan has expired. Scroll below to re-subscribe or choose another subscription plan.`, action: () => {
                             setTimeout(() => {
                                 Paymentinitdialog({
                                     amt: null,
                                     bal: packedtoolsamount,
                                     nav: navigation,
                                     desc: `Confirm the payment for this order and you're to pay exactly ${packedtoolsamount} to get you  ${packedtoolsamount * 1000000} Hertz Hashrate to be able to proceed. Choose from the available crypto coins below to continue with this transaction.`,
                                     email: e.emailin,
                                     title: "Buy Hashrate",
                                     body: null,
                                 })
                             }, 100);
                         }
                     }) */



