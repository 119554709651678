import { useState, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { FaDollarSign } from 'react-icons/fa';
import '../react-confirm-alert.css'
import Progressdialog from '../components/progres';
import { cryptostopup } from '../config/data';


export const CreatePayment = async (list) => {
    console.log(list)
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", process.env.REACT_APP_CPAY);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "price_amount": list.amt,
        "price_currency": "usd",
        "pay_currency": list.coin,
        "ipn_callback_url": "https://nowpayments.io",
        "order_id": 'PTl-',
        "is_fee_paid_by_user": false,
        "is_fixed_rate": true,
        "order_description": `This window is the overview of your payment process, make sure you pay exactly the amount inicated above to avoid any transaction difficulties.`
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return await fetch("https://api.nowpayments.io/v1/payment", requestOptions)
    /* .then(response => response.text())
    .then(result => {
        var data = JSON.parse(result)
        console.log(data)
    })
    .catch(error => console.log('error', error)); */
}

export const GetPaymentStatus = async (pid) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", process.env.REACT_APP_CPAY);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return await fetch(`https://api.nowpayments.io/v1/payment/${pid}`, requestOptions)
    /* .then(response => response.text())
     .then(result => console.log(JSON.parse(result)))
     .catch(error => console.log('error', error)); */

}


export const Paymentinitdialog = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        closeOnClickOutside: true,
        closeOnEscape: true,
        customUI: ({ title, onClose }) => {
            return (
                <MainElem list={list} close={onClose} />
            )
        }
    });
}

const MainElem = ({ list, close }) => {
    const [idx, setidx] = useState(0);
    const [crtname, setcrtname] = useState("Bitcoin");
    const [coin, setcoin] = useState('btc')
    const [amount, setAmount] = useState(50);
    const amountref = useRef()
    return (
        <div className='mx-auto p-8 max-w-xl rounded-lg bg-gray-800 w-full m-6 lg:w-max lg:h-max' style={{ zIndex: '99999' }}>
            <div className="">
                <h1 className="font-extrabold text-xl mt-4 text-white">
                    {list.title}
                </h1>
                <div className="my-2">
                    {list.body}

                    {list.body === null && (<div className="flex flex-col gab-4">
                        <p className="text-sm my-4 text-yellow-50">
                            Enter the amount you wish to add up to your account balance and select
                            the cryptocurrency you wish to deposite and press on continue to
                            proceed with the payment process.
                        </p>
                        <div className="relative flex items-center">
                            <input
                                className="rounded-lg border-1 border-accent-light-100 bg-accent-gray-100 bg-opacity-10 py-2 pl-12 text-2xl font-bold pr-8 placeholder:text-accent-light-100 text-black outline-none focus:border-1 focus:border-yellow-100 w-full"
                                placeholder="Enter amount here"
                                type="number"
                                required
                                ref={amountref}
                                onChange={(e) => {
                                    setAmount(e.target.value);
                                }}
                                value={amount}
                            ></input>
                            <FaDollarSign className="absolute left-4 top-3" size={24} color="#6D6E72" />
                        </div>
                    </div>)}


                </div>
                <div className="flex relative gap-2 my-2 justify-center mt-4 border-1 border-accent-gray-100 py-8 rounded-lg">
                    {cryptostopup().map((value, index) => (
                        <div
                            className={`rounded-full p-2 ${idx === index ? "bg-green-500" : "bg-gray-100 bg-opacity-30 "
                                } flex items-center justify-center cursor-pointer`}
                            key={index}
                            onClick={() => {
                                setidx(index);
                                setcoin(value.symb.toLowerCase());
                                setcrtname(value.coin);
                            }}
                        >
                            <img src={value.icon} alt={value.coin} className="h-8 w-8" />
                        </div>
                    ))}
                    <h1 className="absolute text-center text-white mt-2 bottom-0">
                        {crtname}
                    </h1>
                </div>
            </div>
            <div className="flex gap-4 mt-8 justify-end">
                <button className="p-2 hover:bg-accent-gray-100 bg-opacity-30 rounded-lg text-red-500" onClick={() => close()}>Cancel</button>
                <button className="p-2 hover:bg-accent-gray-100 bg-opacity-30 rounded-lg text-green-500" onClick={() => {

                    Progressdialog({ msg: "Please wait whiles initializing payment process.", ref: list.ref })
                    /*  CreatePayment({ amt: list.amt === null ? amount : list.amt, coin, email: InitUser().email, username: InitUser().username, fees: list.amt === null ? true : false })
                         .then((value) => value.text())
                         .then((value) => {
 
                             var data = JSON.parse(value)
                             close()
 
                             list.nav(paymentshop, { state: { code: data.payment_id } })
                         }).catch((err) => {
                             Info({ title: "Process Error", msg: err.message })
                         }) */

                }}>Continue</button>
            </div>
        </div >

    );

}

export const Paymentstatusdialog = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        closeOnClickOutside: false,
        closeOnEscape: false,
        customUI: ({ title, onClose }) => {
            return (
                <div className='mx-auto p-8 max-w-xl rounded-lg  w-5/6 m-6 lg:w-max lg:h-max' style={{ zIndex: '99999' }}>

                </div>
            )
        }
    });
}
