// ContextProviderClass.js
import React, { createContext, useContext, useState } from 'react';

const ContextProviderClass = createContext();

export const ContextProvider = ({ children }) => {
    const [onClosePopup, setOnClosePopup] = useState(null);

    return (
        <ContextProviderClass.Provider value={{ onClosePopup, setOnClosePopup }}>
            {children}
        </ContextProviderClass.Provider>
    );
};

export const useContextProvider = () => {
    return useContext(ContextProviderClass);
};
