import React from "react";
import AuthComponent from "../components/auth";
import { Helmet } from "react-helmet";
const AuthPage = () => {
  return (
    <div className="w-full md:w-5/6 lg:w-3/6">
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="%PUBLIC_URL%/logo1.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Signup or login to the altimate one stop shop for all your hacking tools; cryptocurrency, bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp"
        />
        <meta
          name="keyword"
          content="cryptocurrency, bank logs, bank cheque, carding, spamming, bank, account, hacking, vpn, socks5, rdp"
        />
        <link rel="canonical" href="http://packedtools.com/userauthenticationpage" />
        <titie>
          Authtication - VPN, SOCKS5, RDP, Remote Desktop Protocol, VPN, Viertual, hacking,
          Private Network, Admin Panel
        </titie>
      </Helmet>
      <div className="mt-28">
        <AuthComponent />
      </div>
    </div>
  );
};

export default AuthPage;
