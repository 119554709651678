// src/AddDa.js
import React, { useState } from 'react';
import { SetADoc } from '../config/db';
import moment from 'moment/moment';
import { Info } from '../config/functions';

const AddData = () => {
    const [email, setEmail] = useState('');
    const [selectedOption, setSelectedOption] = useState('daily');

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const amount = {
            daily: 55,
            weekly: 85,
            monthly: 105,
            unlimited: 1000,
        }[selectedOption];

        const currentDate = new Date().toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
        });

        const data = {
            hash: "",
            plan: selectedOption,
            date: moment().format("LLL"),
            counter: 0,
            balance: 0,
            amount,
        };

        console.log(email, data);
        SetADoc('transactionreceipt', email, data).then((value) => {
            Info({ title: "Success", msg: "Data has been added successfully" })
        }).catch((err) => Info({ title: "Error", msg: "An error Occured: " + err, action: () => { } }))
    };

    return (
        <form className="flex flex-col w-full px-4 py-4 lg:w-4/6 xl:w-2/5 mt-4" onSubmit={handleSubmit}>
            <div className="flex flex-col items-left mt-8">
                <label htmlFor="email" className="mb-2 text-white">
                    Email
                </label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    className="w-full p-2 border border-black rounded-lg outline-none"
                    required
                    placeholder='Enter user email here'
                />
            </div>
            <div className="flex flex-col items-left mt-4">
                <label htmlFor="plan" className="mb-2 text-white">
                    Select Plan
                </label>
                <select
                    id="plan"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    required
                >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="unlimited">Unlimited</option>
                </select>
            </div>
            <div className="flex justify-center mt-6">
                <button
                    type="submit"
                    className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"

                >
                    Submit
                </button>
            </div>
        </form>
    );
};

export default AddData;
