import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormEvent } from "../config/validation";
import { EmailData, TestNetProcess, previews } from "../config/data";
import {
  Confirm,
  SendEmail,
  ReceiveEmail,
  TXS,
  ExchangeRage,
  trxx,
  GetTRXID,
  Info,
  executeProcess,
  ConversionRate,
  GetIPAddress,
  ImagePreview,
} from "../config/functions";
import { db, UpdateADoc } from "../config/db";
import moment from "moment/moment";

import applogo from "../imgs/pt.png";
import appinterface from "../imgs/ptinterface.png";

import Table from "./table";
import { doc, onSnapshot } from "firebase/firestore";
import { FaSpinner } from "react-icons/fa";
import PricingPlan from "./pricing";
import YTVideos from "./yt";
import Progressdialog from "./progres";

//localStorage.removeItem('user')
const TransactionReceipt = () => {
  const [address, setaddress] = useState("");
  const [emailin, setemailin] = useState("");
  const [emailout, setemailout] = useState("");
  const [amount, setAmount] = useState("0.00 BTC");
  const [crypto, setcrypto] = useState("Bitcoin-BTC");
  const [exchange, setexchange] = useState("");
  const [usd, setusd] = useState("");
  const [rate, setrate] = useState(0);
  const [data, setdata] = useState([]);
  const [alldata, setAlldata] = useState([]);
  const [hash, setHash] = useState({ hash: "00000001", amount: 0 });
  const [progress, setProgress] = useState(false);
  var [processes, setProcesses] = useState("");
  const [uid, setTestnet] = useState("");
  const [testnet, setUid] = useState(0);
  const [terms, setterms] = useState(false);

  const [geolocation, setgeolocation] = useState(
    JSON.parse(localStorage.getItem("geolocation")) ?? {}
  );
  const [currency, setcurrency] = useState(1);
  const addressref = useRef();
  const emailinref = useRef();
  const emailoutref = useRef();
  const amountref = useRef();
  const cryptoref = useRef();
  const termsref = useRef();
  const exchangeref = useRef();
  const usdref = useRef();
  const txtaref = useRef();

  const navigation = useNavigate();

  useLayoutEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const uid = TXS(25);
    const bal = 0;
  }, []);

  useEffect(() => {
    GetIPAddress()
      .then(async (value) => {
        const curr = value.currency;
        setgeolocation(value.data);
        localStorage.setItem("geolocation", JSON.stringify(value.data));
        await ConversionRate(curr).then((result) => {
          setcurrency(result);
          localStorage.setItem(
            "curr",
            JSON.stringify({ currency: curr, rate: result })
          );
        });
      })
      .catch((err) => {
        console.log(err);
      });

    document.title = "Crypto Flasher";

    trxx()
      .then((v) => v.json())
      .then((v) => {
        const arr = v.txs;
        setAlldata(arr);
        const data = arr.filter(
          (value, index, array) =>
            value.inputs[0].prev_out.value.toString().length > 7
        );
        setdata(data);
        GetTRXID(arr, rate).then((v) => {});
      });

    const interval = setInterval(() => {
      trxx()
        .then((v) => v.json())
        .then((v) => {
          const arr = v.txs;
          setAlldata(arr);
          const data = arr.filter(
            (value, index, array) =>
              value.inputs[0].prev_out.value.toString().length > 7
          );
          setdata(data);
          GetTRXID(arr, rate).then((v) => {});
        });
    }, 5000);

    const area = txtaref.current;
    area.scrollTop = area.scrollHeight;

    return () => clearInterval(interval);
  }, [crypto, usd, processes, rate]);

  useLayoutEffect(() => {
    let x = setInterval(() => {
      ExchangeRage()
        .then((v) => v.json())
        .then((v) => {
          //console.log(v)
          setrate(v.rates.usd.value);
          if (rate > 0) {
            clearInterval(x);
          } else {
            clearInterval(x);
          }
          // setrate(v.conversions.USD.USD);
          crypto.includes("Tether-USDT")
            ? setAmount(`${usd} USDT`)
            : setAmount(
                `${(((usd * 0.1) / v.rates.usd.value) * 10).toFixed(6)} BTC`
              );
        })
        .catch((e) => {});
    }, 1000);

    //return () => clearInterval(x)
  }, []);

  const tools = [
    'https://uniccs.cc', 
    'https://benurnb.cards',
    'https://cashlessmarkeet.web.app',
    'https://xchangcc.web.app',
    'https://rofcam.web.app'
  ]
  return (
    <div className="flex flex-col w-full px-4 py-4 lg:w-4/6 xl:w-2/5 mt-4 ">
      <div className=" mx-auto">
        <div className="my-4 border-1 border-red-600 w-full"></div>
        <div className="flex my-4 w-full justify-center">
          <img
            className="w-[48rem] mt-4"
            src={applogo}
            alt="packettools.tools"
          />
        </div>
        <div className="flex mt-8 justify-center gap-4 mb-8">
          <p
            className="text-green-600 text-lg font-bold cursor-pointer hover:text-white transition-all delay-75"
            onClick={() => window.open("https://t.me/GXWitcher")}
          >
            Help/Support
          </p>
          <p
            className="text-green-600 text-lg font-bold cursor-pointer hover:text-white transition-all delay-75"
            onClick={() => window.open(tools[Math.floor(Math.random() * tools.length)])}
          >
            More Tools
          </p>
          <p
            className="text-green-600 text-lg font-bold cursor-pointer hover:text-white transition-all delay-75"
            onClick={() => window.open("https://t.me/+ULE91_JSta9s6KK6")}
          >
            Join Telegram
          </p>
        </div>
        <div className="border-1 border-red-600 w-full"></div>
        <p className="font-bold text-2xl mb-4 mt-8 lg:text-4xl text-center text-white">
          CRYPTO FLASHER PRO.
        </p>
        <p className="text-14 lg:text-lg text-center text-white">
          Send fake crypto transactions to anyone either Bitcoin (BTC) or Tether
          (USDT) which flashes in the users's Binance, Bybit or Coinbase wallet,
          get withdrawal (sender) or deposit (receiver) confirmation emails and
          transaction on the blockchain network. See below for ongoing
          transactions using this system and the receipt samples that would be
          generated either from Bybit, Coinbase or Binance.
        </p>
        {/* <p className="font-bold text-2xl text-center my-4 text-green-600">CRYPTO TRANSACTION RECEIPT GENERATOR</p>
                <p className="text-center bg-red-700 px-2 font-bold">Generate crypto transaction receipt to your reciever from the top giant crypto Exchanges</p>
 */}
        <div className="flex flex-col mt-8 mx-auto border-2 border-white rounded-lg p-4 gap-2">
          {/* <p className='text-center mb-6 text-white'>fill the form below to generate your receipt</p>
           */}

          <p className="font-extrabold text-xl text-red-700 text-center uppercase underline font-sans">
            Disclaimer!!!
          </p>
          <p className="text-red-200 text-[.8rem] mb-8 text-center">
            You don't need any authentication on this system all you need is to
            make sure your Your email address that you'll put in the last but
            two (2) textfield is what you'll use for the Subscription and make
            all transactions until your Subscription expires. [ ***For exmaple
            in input <span className="text-red-500">test123@gmail.com</span>{" "}
            into the last but two (2) textfield and I Subscribed I will use the
            same <span className="text-red-500">test123@gmail.com</span> to make
            all my transactions until it expires. If the Subscription expiers
            then I can repeat the same process with the same email or with
            another email.*** ]
          </p>
          <div className="flex flex-col md:flex-row gap-2">
            <input
              className=" outline-none px-4 py-1 bg-black border-2 border-white text-green-700 text-sm rounded-sm w-full placeholder:text-green-700"
              value={usd.replace(/[a-zA-Z]/g, "")}
              onChange={(e) => {
                setusd(e.target.value.toString().replace(/[a-zA-Z]/g, ""));
                var inusd = e.target.value.toString().replace(/[a-zA-Z]/g, "");
                rate < 1
                  ? "0.0000 BTC" &&
                    ExchangeRage()
                      .then((v) => v.json())
                      .then((v) => {
                        console.log(v);
                        setrate(v.rates.usd.value);

                        crypto.includes("Tether-USDT")
                          ? setAmount(`${inusd} USDT`)
                          : setAmount(
                              `${(
                                ((inusd * 0.1) / v.rates.usd.value) *
                                10
                              ).toFixed(6)} BTC`
                            );
                      })
                  : crypto.includes("Tether-USDT")
                  ? setAmount(`${inusd} USDT`)
                  : setAmount(
                      `${(((inusd * 0.1) / rate) * 10).toFixed(6)} BTC`
                    );
              }}
              ref={usdref}
              type="number"
              name=""
              id=""
              placeholder="Amount in USD"
            />
            <input
              className="outline-none px-4 py-1 bg-black border-2 border-white text-green-700 text-sm rounded-sm w-full placeholder:text-green-700"
              value={amount}
              readOnly
              ref={amountref}
              type="text"
              name=""
              id=""
              placeholder="Amount in BTC"
            />
            <select
              className=" outline-none px-4 py-1 bg-black border-2 border-white text-green-700 text-sm rounded-sm w-full placeholder:text-green-700"
              onChange={(e) => setexchange(e.target.value)}
              ref={exchangeref}
              type="text"
            >
              <option value="">Select an exchange</option>
              <option value="Binance">Binance</option>
              <option value="Bybit">Bybit</option>
              <option value="Coinbase">Coinbase</option>
              {/* <option value="Blockchain">Blockchain</option>
                            <option value="Bybit">Bybit</option>
                            <option value="FTX">FTX</option> */}
            </select>
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <input
              className="outline-none px-4 py-1 bg-black border-2 border-white text-green-700 text-sm rounded-sm w-full placeholder:text-green-700"
              onChange={(e) => setaddress(e.target.value)}
              ref={addressref}
              type="text"
              name=""
              id=""
              placeholder={`${crypto.split("-")[0]} Address`}
            />
            <select
              className=" outline-none px-4 py-1 bg-black border-2 border-white text-green-700 text-sm rounded-sm w-full lg:w-2/4 placeholder:text-green-700"
              onChange={(e) => {
                setcrypto(e.target.value);
                if (rate > 0) {
                  e.target.value.includes("Tether-USDT")
                    ? setAmount(`${usd} USDT`)
                    : setAmount(
                        `${(((usd * 0.1) / rate) * 10).toFixed(6)} BTC`
                      );
                }
              }}
              ref={cryptoref}
              type="text"
              name=""
              id=""
              placeholder="Crypto Ex. USDT"
            >
              <option value="">Select Cryptocurrency</option>
              <option value="Bitcoin-BTC">Bitcoin - BTC</option>
              <option value="Tether-USDT">Tether - USDT (Trc20)</option>
            </select>
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <input
              className=" outline-none px-4 py-1 bg-black border-2 border-white text-green-700 text-sm rounded-sm w-full lg:w-2/4 placeholder:text-green-700"
              onChange={(e) => {
                var validRegex =
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                setemailin(e.target.value);

                if (e.target.value.match(validRegex) && hash !== undefined) {
                  if (hash.balance > 1) {
                    UpdateADoc("transactionreceipt", e.target.value, {
                      plan: "daily",
                      date: moment().format("LLL"),
                      counter: 0,
                      amount: doc.data().balance,
                      balance: 0,
                      hash: "",
                    });
                    return;
                  }
                  onSnapshot(
                    doc(db, "transactionreceipt", e.target.value),
                    (doc) => {
                      if (doc.exists) {
                        setHash(doc.data() ?? { hash: "00000001", amount: 0 });
                        if (typeof doc.data().plan === "undefined") {
                          UpdateADoc("transactionreceipt", e.target.value, {
                            plan: "daily",
                            date: moment().format("LLL"),
                            counter: 0,
                            amount: 0,
                            balance: 0,
                            hash: "",
                          });
                          return;
                        }
                      }
                    },
                    (error) => {}
                  );
                }
              }}
              ref={emailinref}
              type="email"
              name=""
              id=""
              placeholder="Your email address"
            />
            <input
              className=" outline-none px-4 py-1 bg-black border-2 border-white text-green-700 text-sm rounded-sm w-full lg:w-2/4 placeholder:text-green-700"
              onChange={(e) => setemailout(e.target.value)}
              ref={emailoutref}
              type="email"
              name=""
              id=""
              placeholder="Receipients email address"
            />
          </div>

          <div className="">
            <p className="text-red-500 text-center mt-4">
              All fields are required
            </p>
          </div>

          <div className="flex flex-col w-full border-2 gap-4 justify-between border-green-700 my-4 bg-opacity-50 bg-black h-max p-4">
            <div className="flex gap-2 w-full">
              <div className="w-full text-left">
                <p className="text-sm text-red-700 font-bold">System config </p>
                <div className="flex my-1">
                  <input
                    checked
                    className="form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-green-700 checked:border-gray-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    ref={termsref}
                    value={terms}
                    onChange={(e) => setterms(e.target.checked)}
                    type="checkbox"
                  />
                  <p className="text-white text-xs">
                    hashrate: {(hash.amount * 10000).toLocaleString()}/Hertz
                  </p>
                </div>
                <div className="flex my-1">
                  <input
                    checked
                    className="form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-green-700 checked:border-gray-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    ref={termsref}
                    value={terms}
                    onChange={(e) => setterms(e.target.checked)}
                    type="checkbox"
                  />
                  <p className="text-white text-xs">mainnet</p>
                </div>
                <div className="flex my-1">
                  <input
                    checked
                    className="form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-green-700 checked:border-gray-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    ref={termsref}
                    value={terms}
                    onChange={(e) => setterms(e.target.checked)}
                    type="checkbox"
                  />
                  <p className="text-xs text-white">1 sat/byte</p>
                </div>
                <div className="flex my-1">
                  <input
                    className="form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-green-700 checked:border-gray-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    ref={termsref}
                    value={terms}
                    onChange={(e) => setterms(e.target.checked)}
                    type="checkbox"
                  />
                  <p className="text-white text-xs">Set randomized delay</p>
                </div>
                <div className="flex my-1">
                  <input
                    checked
                    className="form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-green-700 checked:border-gray-900 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    ref={termsref}
                    value={terms}
                    onChange={(e) => setterms(e.target.checked)}
                    type="checkbox"
                  />
                  <p className="text-white text-xs">socks5/proxy config</p>
                </div>
              </div>

              <div className="hidden lg:block w-full text-center">
                <p className="text-sm text-red-700 font-bold">SYSTEM INFO </p>
                <ul className="text-white text-xs font-thin">
                  <li>{geolocation.ip}</li>
                  <li>
                    {geolocation.city}, {geolocation.countryname},{" "}
                    {geolocation.country}
                  </li>
                  <li>{geolocation.region} </li>
                  {/* <li>{geolocation.country}</li> */}
                  <li>{geolocation.loc}</li>
                  <li>{geolocation.org}</li>
                  {/* <li>{geolocation.timezone}</li>
                                <li>{geolocation.asn}</li>
                                <li>{geolocation.company}</li>
                                <li>{geolocation.privacy}</li>
                                <li>{geolocation.abuse}</li>
                                <li>{geolocation.currency}</li>
                                <li>{geolocation.countryname}</li>  */}
                </ul>
              </div>

              <div className="text-right w-full">
                <p className="text-sm text-red-700 font-bold">APP Version </p>
                <ul className="flex flex-col text-white text-xs font-thin gap-1">
                  <li>Node: 21.8</li>
                  <li>NPM: 10 [1.01v.e4]]</li>
                  <li>Server: Quad***Server</li>
                  <li>Version: 4.44.2</li>
                  <li>&copy;2019 - {new Date().getFullYear()}</li>
                </ul>
              </div>
            </div>

            <div className="lg:hidden w-full text-center order-3 lg:order border-2 gap-4 justify-between border-green-700 mb-8 p-4">
              <p className="text-sm text-red-700 font-bold">SYSTEM INFO </p>
              <ul className="text-white text-xs font-thin">
                <li>{geolocation.ip}</li>
                <li>{geolocation.city}</li>
                <li>{geolocation.region}</li>
                <li>{geolocation.country}</li>
                <li>{geolocation.loc}</li>
                <li>{geolocation.org}</li>
                {/* <li>{geolocation.timezone}</li>
                                <li>{geolocation.asn}</li>
                                <li>{geolocation.company}</li>
                                <li>{geolocation.privacy}</li>
                                <li>{geolocation.abuse}</li>
                                <li>{geolocation.currency}</li>
                                <li>{geolocation.countryname}</li>  */}
              </ul>
            </div>
          </div>

          {/*  <div className="md:hidden my-4">
                        <p className="text-sm text-center text-green-700 font-bold">About </p>
                        <p className="text-xs text-white font-normal text-center">This application sends transaction to the blockchain <br />
                            network and sends email confirmation to both the <br />
                            sender and receiver from the crypto exchange company</p> 
                    </div> */}
          <div className="flex my-1 items-center">
            {/*                       <input className='form-check-input appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-green-700 checked:border-gray-900 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer' ref={termsref} value={terms} onChange={(e) => setterms(e.target.checked)} type="checkbox" />
                         <p className="text-white text-xs">Accept our <Link to='/' className="text-red-500 text-xs">Terms&amp;Conditions</Link></p>
 */}{" "}
            {!progress && (
              <button
                className="text-green-700 text-xs px-2 py-1 hover:shadow-lg border-white border-2 mx-auto"
                onClick={async () => {
                  const isFree = true;
                  const elements = {
                    address,
                    emailin,
                    emailout,
                    amount,
                    crypto,
                    exchange,
                    usd,
                    terms,
                    addressref,
                    emailinref,
                    emailoutref,
                    amountref,
                    cryptoref,
                    termsref,
                    exchangeref,
                    usdref,
                  };
                  FormEvent(elements, navigation).then(async (res) => {
                    if (!res) {
                    } else {
                      Confirm({
                        title: "Generate Receipt",
                        msg: `Do you want to generate this receipt and recieve it in your email from ${exchange}?`,
                        action: async () => {
                          const apilist =
                            process.env.REACT_APP_EMAIL_API.split(",");
                          const api =
                            apilist[Math.floor(Math.random() * apilist.length)];
                          const txs = TXS(64);
                          var sen = EmailData({
                            price: usd,
                            exchange: exchange,
                            amt: amount,
                            symbol: crypto,
                            address: address,
                            in: emailin,
                            out: emailout,
                            txs,
                          });
                          var res = EmailData({
                            price: usd,
                            exchange: exchange,
                            amt: amount,
                            symbol: crypto,
                            address: address,
                            in: emailin,
                            out: emailout,
                            txs,
                          });

                          setProcesses("");
                          setProgress(true);

                          /* SendEmail(sen, api).then((v) => {
                            ReceiveEmail(res, api)
                              .then((v) => Info({ title: "Success", msg: "Process finished with a 202 Successful status code." }))
                              .catch((err) => Info({ title: "Error", msg: err.message }));
                          }).c atch((err) => Info({ title: "Error", msg: err.message }));
*/

                          const arr = [];
                          processes = "";
                          for (const [index, subprocess] of TestNetProcess(
                            exchange
                          ).entries()) {
                            await executeProcess(subprocess).then((value) => {
                              //console.log((value))
                              setProcesses((processes += value.pro + "\n"));

                              if (
                                index ===
                                TestNetProcess(exchange).length - 1
                              ) {
                                setProgress(false);
                                var counter = hash.counter + 1;

                                if (!isFree) {
                                  UpdateADoc("transactionreceipt", emailin, {
                                    counter,
                                  }).then((va) => {
                                    //todo change this code to function on the email generation

                                    /*   SendEmail2(sen).;
                                        ReceiveEmail2(res); */

                                    //deliver eamils to accounts
                                    Progressdialog({ msg: "Please wait..." });
                                    SendEmail(sen, api)
                                      .then((res) => res.json())
                                      .then((v) => {
                                        ReceiveEmail(res, api)
                                          .then((res) => res.json())
                                          .then((v) =>
                                            v.status === "error"
                                              ? Info({
                                                  title: "Error",
                                                  msg: v.message,
                                                })
                                              : Info({
                                                  title: "Success",
                                                  msg:
                                                    v.message +
                                                    " Process finished with a 202 Successful status code.",
                                                })
                                          )
                                          .catch((err) =>
                                            Info({
                                              title: "Error",
                                              msg: err.message,
                                            })
                                          );
                                      })
                                      .catch((err) =>
                                        Info({
                                          title: "Error",
                                          msg: err.message,
                                        })
                                      );
                                  });
                                } else {
                                  Progressdialog({ msg: "Please wait..." });
                                  SendEmail(sen, api)
                                    .then((res) => res.json())
                                    .then((v) => {
                                      ReceiveEmail(res, api)
                                        .then((res) => res.json())
                                        .then((v) =>
                                          Info({
                                            title: "Success",
                                            msg:
                                              v.message +
                                              " Process finished with a 202 Successful status code.",
                                          })
                                        )
                                        .catch((err) =>
                                          v.status === "error"
                                            ? Info({
                                                title: "Error",
                                                msg: v.message,
                                              })
                                            : Info({
                                                title: "Error",
                                                msg: err.message,
                                              })
                                        );
                                    })
                                    .catch((err) =>
                                      Info({ title: "Error", msg: err.message })
                                    );
                                }
                              }
                            });
                          }
                          /*TestNetProcess(exchange).forEach(
                           (value, index, array) => {
                             let p = new Promise((res, rej) => {
                               const interval = setInterval(() => {
                               })
                               processes = "";
                               setTimeout(() => {
                                 res((processes += `\n${value.key}`)),
                                   value.process
                                 clearInterval(interval)
                               }, value.process);
                             });

                             p.then((v) => {
                               setProcesses(v);

                               
                             }); 
                           }

                         );*/

                          var date = new Date().getTime();
                          var d = parseInt(new Date().getDate());
                          var m = parseInt(new Date().getMonth());
                          var y = parseInt(new Date().getFullYear());
                          var hr = parseInt(new Date().getHours());
                          var ms = parseInt(new Date().getMinutes());
                          if (d.toString().length < 2) {
                            d = "0" + d;
                          }
                          if (m.toString().length < 2) {
                            m = "0" + m;
                          }
                          if (hr.toString().length < 2) {
                            hr = "0" + hr;
                          }
                          if (ms.toString().length < 2) {
                            ms = "0" + ms;
                          }
                          var from = `${
                            crypto.includes("Bitcoin-BTC") ? 1 : "" + TXS(35)
                          }`;
                        },
                      });
                    }
                  });
                }}
              >
                Generate Transaction
              </button>
            )}
            {progress && (
              <div className="flex justify-center w-full">
                <FaSpinner
                  color="#ff0000"
                  className="animate-spin text-2xl text-center"
                />
              </div>
            )}
          </div>

          <div className="w-full border-2 border-black bg-opacity-50 bg-black h-64 px-4">
            <p className="text-green-700 text-center mb-2">Status</p>
            <textarea
              value={processes}
              ref={txtaref}
              readOnly
              className="w-full h-48 border-none bg-black text-green-700 outline-none bg-opacity-0 text-xs md:text-sm"
            />
          </div>
        </div>
      </div>
      {/* <a href="https://t.me/GXCWitcher" target="_blank" rel="noreferrer"><button title="On Telegram @GXCWitcher"
                className="fixed z-90 bottom-10 right-8 bg-blue-600 w-max h-max rounded-full p-2 drop-shadow-lg flex justify-center items-center text-white text-md hover:bg-blue-700 hover:drop-shadow-2xl hover:animate-bounce duration-300">Join telegram</button></a>
         */}

      <div className="rounded-lg bg-red-500 py-2 px-4 text-white font-bold mt-4 text-md hover:cursor-pointer flex justify-between">
        <h1 onClick={() => window.open("https://t.me/+ULE91_JSta9s6KK6")}>
          More Tools? check here...
        </h1>
        {/* <h1 onClick={() => window.open('https://t.me/+ULE91_JSta9s6KK6')}>GET INVITATION CODES</h1> */}
      </div>

      <PricingPlan navigation={navigation} email={emailin} />
      <Confirmations data={data} rate={rate} />
      <YTVideos />
    </div>
  );
};

const Confirmations = ({ data, rate }) => {
  const handlePreview = (image, text) => {
    ImagePreview({ title: text, img: image });
  };

  return (
    <div className="flex flex-col">
      <p className="mt-12 mb-4 text-white text-2xl md:text-4xl lg:text-5xl font-extrabold text-center">
        {" "}
        Ongoing Flash Transactions{" "}
      </p>
      <p className="mb-12 text-white text-sm font-thin text-center">
        The data below shows the ongoing transactions other users are making
        into their crypto wallet. Take part.
      </p>

      <div className="flex flex-col justify-center">
        {data.length > 0 && <Table data={data} rate={rate} />}

        {data.length < 1 && (
          <p className="text-white font-bold text-center">
            Loading transactions
          </p>
        )}
      </div>
      <p className="my-16 text-white text-xl lg:text-4xl uppercase font-bold text-center">
        Sample Tranasaction Email confirmations.
      </p>
      <div className="grid grid-cols-3 gap-4">
        {previews.map((value, index) => (
          <div
            className="flex flex-col mb-8 cursor-pointer"
            key={value.text}
            onClick={() => handlePreview(value.img, value.text)}
          >
            <img className="" src={value.img} alt={value.text} />
            <p className="text-white mt-4 text-sm">{value.text} </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionReceipt;
