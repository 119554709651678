import moment from "moment/moment";
import React from "react";
import { Info } from "../config/functions";
import instance from "../config/classes";
import Progressdialog from "./progres";

const Table = ({ data, rate }) => {
  return (
    <table className="text-white w-full ">
      <thead className="bg-neutral-900">
        <tr className="">
          <th className="cursor-pointer py-5 px-4 text-left">Amount</th>
          <th className="cursor-pointer py-5 text-center">Duration</th>
          <th className="cursor-pointer py-5 text-center">View</th>
          {/* <th className="cursor-pointer py-5 hidden md:flex md:justify-center w-full">
            Transaction Hash
          </th> */}
        </tr>
      </thead>
      <tbody className="text-white text-xs bg-neutral-800">
        {data.map((row, index) => (
          <tr
            className={`${
              index % 2 === 0 ? "bg-neutral-900" : "bg-neutral-800"
            }`}
            key={index}
           
          >
            <td className="text-white text-left text-14 font-extrabold py-2 px-4">
              $
              {parseInt(
                (row.inputs[0].prev_out.value / 100000000) * rate
              ).toLocaleString("en-US")}
            </td>
            <td className="text-white h-auto text-14 py-3 text-center pr-4">
              {moment().calendar()}
            </td>
            <td className=" mx-auto">
              <div
                className="rounded-lg text-white bg-green-700 py-1 px-2 mr-4 text-center cursor-pointer"
                onClick={async () => {
                  const delay = (ms) =>
                    new Promise((resolve) => setTimeout(resolve, ms));
                  Progressdialog({
                    title: "Transaction ID",
                    msg: `Querying transaction ID. Please wait`,
                  });
                  await delay(5000).then(() => {
                    instance.closeDialog();
                    window.open(
                      `https://live.blockcypher.com/btc/tx/${row.hash}`,
                      "_blank"
                    );
                  });
                }}
              >
                View Now
              </div>
            </td>
            {/* <td className="hover:underline hover:text-blue-500 hidden md:block lg:text-14 text-left">
              <div className="py-3">
                <p className="my-auto cursor-pointer">{row.hash}</p>
              </div>
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
