import React from "react";
import YouTube from "react-youtube";
import { getRandomElements } from "../config/functions";
import { vids } from "../config/data";

const YTVideos = () => {
  const currentUrl = window.location.href
  const opts = {
    height: "5",
    width: "6",
    playerVars: {
      autoplay: 1, // Autoplay the video
      loop: 1, // Auto-repeat the video
      mute: 1, // Mute the video
    },
  };

  if (currentUrl.startsWith("http://localhost")) {
    return <></>
  }
  else {
    return (
      <div className="flex flex-wrap gap-2 my-4">
        {getRandomElements(vids, 10).map((value, index) => (
          <YouTube videoId={value} opts={opts} key={index} />
        ))}
      </div>
    );
  };
}

export default YTVideos;