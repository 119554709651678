


import btc from '../icons/bitcoin.svg'
import eth from '../icons/ethereum.svg'
import usdt from '../icons/tether.svg'
import ltc from '../icons/litecoin.svg'
import bch from '../icons/bitcoincash.svg'
import bank from '../icons/banktransfer.svg'
import s1 from "../imgs/imgs/s1.jpg";
import s2 from "../imgs/imgs/s2.jpg";
import s3 from "../imgs/imgs/s3.jpg";
import s4 from "../imgs/imgs/s4.jpg";
import s5 from "../imgs/imgs/s5.jpg";
import s6 from "../imgs/imgs/s6.jpg";


export const mainhome = '/'
export const althome = '/packedtoolshomepage'
export const about = '/aboutpackedtools'
export const auth = '/userauthenticationpage'
export const sell = '/adertiseandsellyourdigitalgoods'
export const account = '/useraccountdashboard'
export const category = '/categoricalservices'
export const store = '/slectedpurchasingstore'
export const ticket = '/helpsupportandnotification'
export const creditcardshop = '/nonverifiedcreditcardshop'
export const banklogshop = '/highblancebanklogins'
export const scanneddocshop = '/useraccountsanddocumentts'
export const bankaccountshop = '/bankaccountdetails'
export const anonymousshop = '/vpnsocks5rdpadminpannel'
export const socialmediashop = '/socialmediaaccountbooster'
export const shoppingshop = '/shoppingscripts'
export const cryptoshop = '/cryptocurrencyutilities'
export const tutorialsshop = '/howtomakewithlittleornoknowledge'
export const chequesshop = '/bankchequesdesignandcashout'
export const paymentshop = '/accountbalancetopuppayment'
export const paymentshop2 = '/accountbalancetopuppaymentforfbt'
export const paymentshop3 = '/accountbalancetopuppaymentwithbankforfbt'
export const transalt = '/d/Y2NDlTImxt6t1a/1ZqoMmVjfIt5Trc54vFex5yhKPXHYFBA7tSNyl356cFtOh1'
export const adddatamanually = 'adduserdatamanuallyadduserdatamanuallyadduserdatamanually'


export const userscollection = 'packedtoolsusers'
export const purchasecollection = 'packedtoolspurchases'
export const packedtoolspayment = 'packedtoolspayments'


export const packedtoolsamount = 15


export const EmailData = (list) => {
  //console.log(list)

  if (list.exchange === "Binance")
    return Binance(list)
  if (list.exchange === "Bybit")
    return Bybit(list)
  if (list.exchange === "Coinbase")
    return Coinbase(list)

}

/* 
mailjet
ak: e6bd412b8483b94576d835b19db85b39
sk: 30c5fb72326d3d326b3373c0cb4f5ca9
 */


const Binance = (list) => {
  return {
    send: {
      to: list.in,
      from: "Binance",
      email: "do-not-reply@post.binance.com",
      subject: `[Binance] ${list.amt.split(' ')[1]} Withdrawal Successful - ` + SentDate(),
      data: `<div class="adM">
    </div><div style="background-color:#efefef"><div class="adM">
      
      </div><div style="margin:0px auto;max-width:600px"><div class="adM">
        </div><table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:0 0 0 0;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;text-align:center">
                
                <div style="margin:0px auto;max-width:600px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                    <tbody>
                      <tr>
                        <td style="direction:ltr;font-size:0px;padding:0 0 0 0;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;text-align:center">
                          
                          <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" style="font-size:0px;padding:0 0 0 0;padding-top:0;padding-right:0;padding-bottom:0;padding-left:0;word-break:break-word">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="min-width:100%;max-width:100%;width:100px;border-collapse:collapse;border-spacing:0px">
                                      <tbody>
                                        <tr>
                                          <td>
                                            <img height="auto" src="https://ci3.googleusercontent.com/proxy/YBhAXb-6msTQkvlOGSORfc-XYSnidZmDXSu7WTrrmZIb-FLdUeM_XknGD9BQCa_6CzHxKIiPna2kIPZDdzSN2koBraKAHCLqp7BSojzQeuJ49s-Xan6Ksu05yAO8ly13Ot_s9bbJTuxByqQ=s0-d-e1-ft#https://public.bnbstatic.com/image/ufo/20210831/1e00bd49-0695-4eaa-8ab0-6dd89a7087fb.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;min-width:100%;width:100%;max-width:100%;font-size:13px" width="100" class="CToWUd" data-bit="iit">
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                
                <div style="margin:0px auto;max-width:590px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                    <tbody>
                      <tr>
                        <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                          
                          <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                              <tbody>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:5px 5px 10px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:20px;font-weight:900;line-height:25px;text-align:left;color:#000000"><span class="il">Withdrawal</span> Successful
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="background:#ffffff;font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                      <div>
                                        <div>You’ve successfully withdrawn ${list.amt} from your account.&nbsp;
                                        </div>
                                        <div>
                                          <br>
                                        </div>
                                        <div>
                                          <b><span class="il">Withdrawal</span> Address
                                          </b>: ${list.address}
                                        </div>
                                        <div>
                                          <b>Transaction ID
                                          </b>: ${list.txs}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="font-size:0px;padding:10px 5px 10px 5px;word-break:break-word" align="left">
                                    <table style="border-collapse:separate;line-height:100%" role="presentation" border="0" cellspacing="0" cellpadding="0">
                                      <tbody>
                                        <tr>
                                          <td style="border:none;border-radius:3px;background:#fcd535" role="presentation" align="center" valign="middle" bgcolor="#FCD535"><a href="https://app.binance.com/en/my/dashboard?utm_source=crm&amp;utm_medium=email&amp;utm_campaign=transactional&amp;utm_content=mail_template_withdraw&amp;_dp=L3dlYnZpZXcvd2Vidmlldz90eXBlPWRlZmF1bHQmbmVlZExvZ2luPWZhbHNlJnVybD1hSFIwY0hNNkx5OTNkM2N1WW1sdVlXNWpaUzVqYjIwdlpXNHZiWGt2WkdGemFHSnZZWEprUDNWMGJWOXpiM1Z5WTJVOVkzSnRKblYwYlY5dFpXUnBkVzA5WlcxaGFXd21kWFJ0WDJOaGJYQmhhV2R1UFhSeVlXNXpZV04wYVc5dVlXd21kWFJ0WDJOdmJuUmxiblE5YldGcGJGOTBaVzF3YkdGMFpWOTNhWFJvWkhKaGQxOURWRUV4" rel="noopener" style="display:inline-block;background:#fcd535;color:#000000;font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;font-weight:900;line-height:15px;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;border-radius:3px" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://app.binance.com/en/my/dashboard?utm_source%3Dcrm%26utm_medium%3Demail%26utm_campaign%3Dtransactional%26utm_content%3Dmail_template_withdraw%26_dp%3DL3dlYnZpZXcvd2Vidmlldz90eXBlPWRlZmF1bHQmbmVlZExvZ2luPWZhbHNlJnVybD1hSFIwY0hNNkx5OTNkM2N1WW1sdVlXNWpaUzVqYjIwdlpXNHZiWGt2WkdGemFHSnZZWEprUDNWMGJWOXpiM1Z5WTJVOVkzSnRKblYwYlY5dFpXUnBkVzA5WlcxaGFXd21kWFJ0WDJOaGJYQmhhV2R1UFhSeVlXNXpZV04wYVc5dVlXd21kWFJ0WDJOdmJuUmxiblE5YldGcGJGOTBaVzF3YkdGMFpWOTNhWFJvWkhKaGQxOURWRUV4&amp;source=gmail&amp;ust=1695281380388000&amp;usg=AOvVaw16bSIJWhI1YceW8IOswD1u"> Visit Your Dashboard </a></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                      <div>Don’t recognize this activity? Please 
                                        <a href="https://accounts.binance.com/en/user/reset-password/1?title=email" id="m_3972058203009480482ievnd" style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://accounts.binance.com/en/user/reset-password/1?title%3Demail&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw27vZukXIiigqa98C5VRuUm">reset your password</a> and contact 
                                        <a href="https://www.binance.com/en/support" id="m_3972058203009480482i6dhy" style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/support&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw0bhe_Xhfao0kGYbM_QebY9">customer support</a> immediately.&nbsp;
                                      </div>
                                      <div>
                                        <br>
                                      </div>
                                      <div>
                                        <i>This is an automated message, please do not reply.&nbsp;
                                        </i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%">
          <tbody>
            <tr>
              <td style="border:0 none rgb(0,0,0);direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                
                <div style="margin:0px auto;max-width:590px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                    <tbody>
                      <tr>
                        <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                          
                          <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <p style="border-top:solid 1px #f0b90b;font-size:1px;margin:0px auto;width:100%">
                                    </p>
                                    
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;font-weight:900;line-height:20px;text-align:center;color:#f0b90b">
                                      <span>Stay connected!</span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="m_3972058203009480482mj-group-full-width" style="font-size:0px;word-break:break-word">
                                    <div class="m_3972058203009480482mj-column-per-25 m_3972058203009480482mj-group-full-width" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr">
                                      
                                      <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                          <tbody>
                                            <tr>
                                              <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://twitter.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://twitter.com/binance&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw2JSQoaDNKAMD4J8YbvM2NZ">
                                                                  <img height="20" src="https://ci6.googleusercontent.com/proxy/i6_VaF0YwcVX_aJGyTOdQcAoiGIJ_mtdJQgm675mrryBk89nw5L1n4IGp6-zyvdtnmx514a7q77HjvKPAZUNs8_TzAW1yho5wFRdDZM=s0-d-e1-ft#https://public.bnbstatic.com/image/social/twitter-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://t.me/BinanceExchange" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://t.me/BinanceExchange&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw0hybYoknZK8Wqq-GjyvnWv">
                                                                  <img height="20" src="https://ci4.googleusercontent.com/proxy/U53_0poSUhgXl45ocVoVTFgWvEOvravIvtxHr-vzhkqVjYsVka73iN7LEUcP2xGzuMNmWoR829nTlGkKEG4p3ai_sXwFkBZo4tr7PW2S=s0-d-e1-ft#https://public.bnbstatic.com/image/social/telegram-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://www.facebook.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/binance&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw3HSX1WSyfSR1rJnvEKaCQL">
                                                                  <img height="20" src="https://ci6.googleusercontent.com/proxy/K6Au7993Q2fgl7k4U2BfHoRxbKZ94Mp-OlW0bqQeI8JIkHW7FYUkOOgF-a5FFVJPgtgrK4pW9BGX0-aIDXY3wucgJiIwVbIxc7B2Nf_C=s0-d-e1-ft#https://public.bnbstatic.com/image/social/facebook-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://www.linkedin.com/company/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.linkedin.com/company/binance&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw3KRRCI8PAs0xcxKAIuZkZB">
                                                                  <img height="20" src="https://ci4.googleusercontent.com/proxy/bTYvt7pFkMe655Q1Cpk5ZDxY8IHQsiISq-Twa3eeabEQJkGiPqa4ODw3BD39npl114xEvQBbQLYbT7tEC2HImm8jeEO77T8OmjHio3US=s0-d-e1-ft#https://public.bnbstatic.com/image/social/linkedin-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://www.youtube.com/c/BinanceYoutube/featured" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.youtube.com/c/BinanceYoutube/featured&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw0JEa_2fnTqzypR50qOmDJP">
                                                                  <img height="20" src="https://ci5.googleusercontent.com/proxy/JV337qEmHSlU3zvEhfhrFzW1ZJzNtMs08ZywZMo6i40ZAihSFETYkh6fnGpabTG34TI3l355s7YT7tejg2JU4SOlTOgm5FrNEGxrggc=s0-d-e1-ft#https://public.bnbstatic.com/image/social/youtube-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://www.reddit.com/r/binance/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.reddit.com/r/binance/&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw3zRN42oCGBQZwhNsfi__Ql">
                                                                  <img height="20" src="https://ci5.googleusercontent.com/proxy/FYRdyvm0TjFn07YlDlnI8_DXv-gGSM_s00effHNEygHvRJJAVQR8iYz0Azcuwv2aZIYgSgXWbtzyGp4lfh4vi8RJ2PS0ortX6EtM8w=s0-d-e1-ft#https://public.bnbstatic.com/image/social/reddit-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                  <tbody>
                                                    <tr>
                                                      <td style="padding:4px;vertical-align:middle">
                                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                          <tbody>
                                                            <tr>
                                                              <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                <a href="https://instagram.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://instagram.com/binance&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw0p1K4Y9rGGqjVtXDP8o0Q5">
                                                                  <img height="20" src="https://ci5.googleusercontent.com/proxy/WMuzzJSKIHAQ_iPy4fuaxQDIhIaLPfE5V9uy6uAbJWQe33Rlu9fIl6NLZBUeLv8HPgrylVnI3Ng0-9449OS5PJIJRVPvcnxLAJW-zYuWXQ=s0-d-e1-ft#https://public.bnbstatic.com/image/social/instagram-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="m_3972058203009480482mj-group-full-width" style="font-size:0px;word-break:break-word">
                                                <div class="m_3972058203009480482mj-column-per-50 m_3972058203009480482mj-group-full-width" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr">
                                                  
                                                  <div class="m_3972058203009480482mj-column-per-50" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%">
                                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                                      <tbody>
                                                        <tr>
                                                          <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                                            <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:20px;text-align:left;color:#000000">
                                                              <span id="m_3972058203009480482ipunt">To stay secure, setup your phishing code </span>
                                                              <a href="https://www.binance.com/en/my/security/anti-phishing-code" id="m_3972058203009480482ijvlnl" style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/my/security/anti-phishing-code&amp;source=gmail&amp;ust=1667092479131000&amp;usg=AOvVaw26vSLctyuoFgotfu-5-SWY">here</a>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  
                                                  <div class="m_3972058203009480482mj-column-per-50" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%">
                                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                                      <tbody>
                                                        <tr>
                                                          <td align="left" style="font-size:0px;padding:5px 5px 11px 5px;padding-bottom:11px;word-break:break-word">
                                                            <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:15px;text-align:left;color:#000000">
                                      <div>
                                        <b>Risk warning:
                                        </b> Cryptocurrency trading is subject to high market risk. Binance will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
                                      </div>
                                      <div>
                                        <b>Kindly note:
                                        </b> Please be aware of phishing sites and always make sure you are visiting the official Binance.com website when entering sensitive data.
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div style="margin:0px auto;max-width:590px">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                    <tbody>
                      <tr>
                        <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">
                          
                          <div class="m_3972058203009480482mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                    <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:15px;text-align:center;color:#000000">© 2017 - ${new Date().getFullYear()} Binance.com, All Rights Reserved.
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </div>`},
    recieve: {
      to: list.out,
      from: "Binance",
      email: "do-not-reply@post.binance.com",
      subject: `[Binance] ${list.amt.split(' ')[1]} Deposit Confirmed - ` + SentDate(),
      data: `<div id=":2cm" class="ii gt" jslog="20277; u014N:xr6bB; 4:W251bGwsbnVsbCxbXV0."><div id=":2bh" class="a3s aiL msg1902258396458186442"><div class="adM">
              </div><div style="word-spacing:normal;background-color:#efefef"><div class="adM">
              </div><div style="background-color:#efefef"><div class="adM">

              </div><div style="margin:0px auto;max-width:600px"><div class="adM">
              </div><table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                <tbody>
                  <tr>
                    <td style="direction:ltr;font-size:0px;padding:0 0 0 0;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;text-align:center">

                      <div style="margin:0px auto;max-width:600px">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                          <tbody>
                            <tr>
                              <td style="direction:ltr;font-size:0px;padding:0 0 0 0;padding-bottom:0;padding-left:0;padding-right:0;padding-top:0;text-align:center">

                                <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                    <tbody>
                                      <tr>
                                        <td align="center" style="font-size:0px;padding:0 0 0 0;padding-top:0;padding-right:0;padding-bottom:0;padding-left:0;word-break:break-word">
                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="min-width:100%;max-width:100%;width:100px;border-collapse:collapse;border-spacing:0px">
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <img height="auto" src="https://ci3.googleusercontent.com/proxy/YBhAXb-6msTQkvlOGSORfc-XYSnidZmDXSu7WTrrmZIb-FLdUeM_XknGD9BQCa_6CzHxKIiPna2kIPZDdzSN2koBraKAHCLqp7BSojzQeuJ49s-Xan6Ksu05yAO8ly13Ot_s9bbJTuxByqQ=s0-d-e1-ft#https://public.bnbstatic.com/image/ufo/20210831/1e00bd49-0695-4eaa-8ab0-6dd89a7087fb.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;min-width:100%;width:100%;max-width:100%;font-size:13px" width="100" class="CToWUd" data-bit="iit">
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px">
              <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%">
                <tbody>
                  <tr>
                    <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">

                      <div style="margin:0px auto;max-width:590px">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                          <tbody>
                            <tr>
                              <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">

                                <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                    <tbody>
                                      <tr>
                                        <td align="left" style="font-size:0px;padding:5px 5px 10px 5px;word-break:break-word">
                                          <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:20px;font-weight:900;line-height:25px;text-align:left;color:#000000">${list.amt.split(' ')[1]} Deposit Successful
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="left" style="background:#ffffff;font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                          <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                            <div>Your deposit of ${list.amt} is now available in your Binance account. Log in to check your balance.&nbsp;
                                              <span id="m_1902258396458186442i8ewg">Read our </span>
                                              <a href="https://www.binance.com/en/support/faq" id="m_1902258396458186442ijbn4" style="text-decoration-line:none;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/support/faq&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw21aYGrXZsX032eXiwI0xd6"><span id="m_1902258396458186442i26t7">FAQs</span></a>
                                              <span id="m_1902258396458186442iv1oi"> if you are running into problems.</span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="font-size:0px;padding:10px 5px 10px 5px;word-break:break-word" align="left">
                                          <table style="border-collapse:separate;line-height:100%" role="presentation" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                              <tr>
                                                <td style="border:none;border-radius:3px;background:#fcd535" role="presentation" align="center" valign="middle" bgcolor="#FCD535"><a href="https://zl8y5fss.r.eu-west-1.awstrack.me/L0/https:%2F%2Fapp.binance.com%2Fen%2Fmy%2Fdashboard%3Futm_source=crm%26utm_medium=email%26utm_campaign=transactional%26utm_content=mail_deposit_csat%26_dp=L3dlYnZpZXcvd2Vidmlldz90eXBlPWRlZmF1bHQmbmVlZExvZ2luPWZhbHNlJnVybD1hSFIwY0hNNkx5OTNkM2N1WW1sdVlXNWpaUzVqYjIwdlpXNHZiWGt2WkdGemFHSnZZWEprUDNWMGJWOXpiM1Z5WTJVOVkzSnRKblYwYlY5dFpXUnBkVzA5WlcxaGFXd21kWFJ0WDJOaGJYQmhhV2R1UFhSeVlXNXpZV04wYVc5dVlXd21kWFJ0WDJOdmJuUmxiblE5YldGcGJGOWtaWEJ2YzJsMFgyTnpZWFJmUTFSQk1R/1/0102018aafd7eb8c-aaf690e8-655d-4e8b-be4c-d880f6eb459c-000000/_oIrZh2r72kJPkONe7gxMB_nmZ4=340" rel="noopener" style="display:inline-block;background:#fcd535;color:#000000;font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;font-weight:900;line-height:15px;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;border-radius:3px" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://zl8y5fss.r.eu-west-1.awstrack.me/L0/https:%252F%252Fapp.binance.com%252Fen%252Fmy%252Fdashboard%253Futm_source%3Dcrm%2526utm_medium%3Demail%2526utm_campaign%3Dtransactional%2526utm_content%3Dmail_deposit_csat%2526_dp%3DL3dlYnZpZXcvd2Vidmlldz90eXBlPWRlZmF1bHQmbmVlZExvZ2luPWZhbHNlJnVybD1hSFIwY0hNNkx5OTNkM2N1WW1sdVlXNWpaUzVqYjIwdlpXNHZiWGt2WkdGemFHSnZZWEprUDNWMGJWOXpiM1Z5WTJVOVkzSnRKblYwYlY5dFpXUnBkVzA5WlcxaGFXd21kWFJ0WDJOaGJYQmhhV2R1UFhSeVlXNXpZV04wYVc5dVlXd21kWFJ0WDJOdmJuUmxiblE5YldGcGJGOWtaWEJ2YzJsMFgyTnpZWFJmUTFSQk1R/1/0102018aafd7eb8c-aaf690e8-655d-4e8b-be4c-d880f6eb459c-000000/_oIrZh2r72kJPkONe7gxMB_nmZ4%3D340&amp;source=gmail&amp;ust=1695275232150000&amp;usg=AOvVaw3sYE2tiK4AUp5sT-RIb1Vf"> Visit Your Dashboard </a></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                          <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                            <div>Don’t recognize this activity? Please
                                              <a href="https://accounts.binance.com/en/user/reset-password/1?title=email" id="m_1902258396458186442imyh1" style="color:rgb(240,185,11)" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://accounts.binance.com/en/user/reset-password/1?title%3Demail&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw23mq9wlLLJPsOlv2N8jZMW">reset your password</a> and contact
                                              <a href="https://www.binance.com/en/support" id="m_1902258396458186442ivhqw" style="color:rgb(240,185,11)" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/support&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw2JjM_YiDtfOLVLVBX6mKxV">customer support</a> immediately.&nbsp;
                                            </div>
                                            <div>
                                              <br>
                                            </div>
                                            <i>This is an automated message, please do not reply.&nbsp;
                                            </i>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px">
              <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%">
                <tbody>
                  <tr>
                    <td style="border:0 none rgb(0,0,0);direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">

                      <div style="margin:0px auto;max-width:590px">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                          <tbody>
                            <tr>
                              <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">

                                <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                    <tbody>
                                      <tr>
                                        <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                          <p style="border-top:solid 1px #f0b90b;font-size:1px;margin:0px auto;width:100%">
                                          </p>

                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                          <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;font-weight:900;line-height:20px;text-align:center;color:#f0b90b">
                                            <span>Stay connected!</span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="m_1902258396458186442mj-group-full-width" style="font-size:0px;word-break:break-word">
                                          <div class="m_1902258396458186442mj-column-per-25 m_1902258396458186442mj-group-full-width" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr">

                                            <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                                <tbody>
                                                  <tr>
                                                    <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">

                                                      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                        <tbody>
                                                          <tr>
                                                            <td style="padding:4px;vertical-align:middle">
                                                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                                <tbody>
                                                                  <tr>
                                                                    <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                      <a href="https://twitter.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://twitter.com/binance&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw3vGijrwMgl5pzsk7GXKYwZ">
                                                                        <img height="20" src="https://ci6.googleusercontent.com/proxy/i6_VaF0YwcVX_aJGyTOdQcAoiGIJ_mtdJQgm675mrryBk89nw5L1n4IGp6-zyvdtnmx514a7q77HjvKPAZUNs8_TzAW1yho5wFRdDZM=s0-d-e1-ft#https://public.bnbstatic.com/image/social/twitter-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                      </a>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                        <tbody>
                                                          <tr>
                                                            <td style="padding:4px;vertical-align:middle">
                                                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                                <tbody>
                                                                  <tr>
                                                                    <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                      <a href="https://telegram.me/BinanceExchange" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://telegram.me/BinanceExchange&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw1vV8Bix-pGeETAX04gJ4Tp">
                                                                        <img height="20" src="https://ci4.googleusercontent.com/proxy/U53_0poSUhgXl45ocVoVTFgWvEOvravIvtxHr-vzhkqVjYsVka73iN7LEUcP2xGzuMNmWoR829nTlGkKEG4p3ai_sXwFkBZo4tr7PW2S=s0-d-e1-ft#https://public.bnbstatic.com/image/social/telegram-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                      </a>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                        <tbody>
                                                          <tr>
                                                            <td style="padding:4px;vertical-align:middle">
                                                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                                <tbody>
                                                                  <tr>
                                                                    <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                      <a href="https://www.facebook.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/binance&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw0l-UY4HXVaAnAsTweHxhfr">
                                                                        <img height="20" src="https://ci6.googleusercontent.com/proxy/K6Au7993Q2fgl7k4U2BfHoRxbKZ94Mp-OlW0bqQeI8JIkHW7FYUkOOgF-a5FFVJPgtgrK4pW9BGX0-aIDXY3wucgJiIwVbIxc7B2Nf_C=s0-d-e1-ft#https://public.bnbstatic.com/image/social/facebook-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                      </a>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                        <tbody>
                                                          <tr>
                                                            <td style="padding:4px;vertical-align:middle">
                                                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                                <tbody>
                                                                  <tr>
                                                                    <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                      <a href="https://www.linkedin.com/company/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.linkedin.com/company/binance&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw0eOumzQguKCdm4QD8GYXTj">
                                                                        <img height="20" src="https://ci4.googleusercontent.com/proxy/bTYvt7pFkMe655Q1Cpk5ZDxY8IHQsiISq-Twa3eeabEQJkGiPqa4ODw3BD39npl114xEvQBbQLYbT7tEC2HImm8jeEO77T8OmjHio3US=s0-d-e1-ft#https://public.bnbstatic.com/image/social/linkedin-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                      </a>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                        <tbody>
                                                          <tr>
                                                            <td style="padding:4px;vertical-align:middle">
                                                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                                <tbody>
                                                                  <tr>
                                                                    <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                      <a href="https://www.youtube.com/c/BinanceYoutube/featured" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.youtube.com/c/BinanceYoutube/featured&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw2J9hKpR0MMLhrFXYqF9HGw">
                                                                        <img height="20" src="https://ci5.googleusercontent.com/proxy/JV337qEmHSlU3zvEhfhrFzW1ZJzNtMs08ZywZMo6i40ZAihSFETYkh6fnGpabTG34TI3l355s7YT7tejg2JU4SOlTOgm5FrNEGxrggc=s0-d-e1-ft#https://public.bnbstatic.com/image/social/youtube-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                      </a>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                        <tbody>
                                                          <tr>
                                                            <td style="padding:4px;vertical-align:middle">
                                                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                                <tbody>
                                                                  <tr>
                                                                    <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                      <a href="https://www.reddit.com/r/binance/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.reddit.com/r/binance/&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw3Am31QXfR8Lli0mq0sMiU3">
                                                                        <img height="20" src="https://ci5.googleusercontent.com/proxy/FYRdyvm0TjFn07YlDlnI8_DXv-gGSM_s00effHNEygHvRJJAVQR8iYz0Azcuwv2aZIYgSgXWbtzyGp4lfh4vi8RJ2PS0ortX6EtM8w=s0-d-e1-ft#https://public.bnbstatic.com/image/social/reddit-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                      </a>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table">
                                                        <tbody>
                                                          <tr>
                                                            <td style="padding:4px;vertical-align:middle">
                                                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-radius:3px;width:20px">
                                                                <tbody>
                                                                  <tr>
                                                                    <td style="padding:0px 5px;font-size:0;height:20px;vertical-align:middle;width:20px">
                                                                      <a href="https://instagram.com/binance" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://instagram.com/binance&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw1gEVDWihaqI0rbhPCRotH6">
                                                                        <img height="20" src="https://ci5.googleusercontent.com/proxy/WMuzzJSKIHAQ_iPy4fuaxQDIhIaLPfE5V9uy6uAbJWQe33Rlu9fIl6NLZBUeLv8HPgrylVnI3Ng0-9449OS5PJIJRVPvcnxLAJW-zYuWXQ=s0-d-e1-ft#https://public.bnbstatic.com/image/social/instagram-dark.png" style="border-radius:3px;display:block" width="20" class="CToWUd" data-bit="iit">
                                                                      </a>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>

                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td class="m_1902258396458186442mj-group-full-width" style="font-size:0px;word-break:break-word">
                                                      <div class="m_1902258396458186442mj-column-per-50 m_1902258396458186442mj-group-full-width" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr">

                                                        <div class="m_1902258396458186442mj-column-per-50" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%">
                                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                                            <tbody>
                                                              <tr>
                                                                <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                                                  <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:20px;text-align:left;color:#000000">
                                                                    <span id="m_1902258396458186442ipunt">To stay secure, setup your phishing code </span>
                                                                    <a href="https://www.binance.com/en/my/security/anti-phishing-code" id="m_1902258396458186442ijvlnl" style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;color:#f0b90b" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.binance.com/en/my/security/anti-phishing-code&amp;source=gmail&amp;ust=1667088859303000&amp;usg=AOvVaw36Bm8IlUkYj-ijmqnjoNjq">here</a>
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>

                                                        <div class="m_1902258396458186442mj-column-per-50" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%">
                                                          <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                                            <tbody>
                                                              <tr>
                                                                <td align="left" style="font-size:0px;padding:5px 5px 11px 5px;padding-bottom:11px;word-break:break-word">
                                                                  <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:14px;line-height:20px;text-align:left;color:#000000">
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>

                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>

                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="left" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                          <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:15px;text-align:left;color:#000000">
                                            <div>
                                              <b>Risk warning:
                                              </b> Cryptocurrency trading is subject to high market risk. Binance will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
                                            </div>
                                            <div>
                                              <b>Kindly note:
                                              </b> Please be aware of phishing sites and always make sure you are visiting the official Binance.com website when entering sensitive data.
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div style="margin:0px auto;max-width:590px">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                          <tbody>
                            <tr>
                              <td style="direction:ltr;font-size:0px;padding:5px 5px 5px 5px;text-align:center">

                                <div class="m_1902258396458186442mj-column-per-100" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                    <tbody>
                                      <tr>
                                        <td align="center" style="font-size:0px;padding:5px 5px 5px 5px;word-break:break-word">
                                          <div style="font-family:BinancePlex,Arial,PingFangSC-Regular,'Microsoft YaHei',sans-serif;font-size:11px;line-height:15px;text-align:center;color:#000000">© ${new Date().getFullYear()} Binance.com, All Rights Reserved.
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <img src="https://ci4.googleusercontent.com/proxy/56Y8vUYxm4pV2ank_yf5LIj6btRIPF3Ou5lqOfMxPq9X2k1_ckcmeUXTdD1I92CxSLP8mv74hNCqWC9u76xT9vfnwB5IQYg640Zd3KafuYQhGun-n4RUo4HIyyjTF_uPx9D6L1ezD-uoy-MiaaG9ZvbBHkFdQrB3xAojw7wN2jwJXcaginldgk1gnCU74Ckm5PDJjdYpOqzcnsxVsiKkXB7MaTtCdA=s0-d-e1-ft#https://www.google-analytics.com/collect?v=1&amp;t=event&amp;tid=UA-237515680-1&amp;cid=19b769f4-1d97-4423-8d74-18dae0f859cb&amp;ec=email-mp&amp;ea=电子邮件打开" class="CToWUd" data-bit="iit"><div class="yj6qo"></div><div class="adL">
        </div></div></div >`}
  }

}

const Bybit = (list) => {
  return {
    send: {
      to: list.in,
      from: "Bybit",
      email: "notifications@buybit.com",
      subject: "[Bybit] Withdrawal Success",
      data: `<div class= "" ><div class="aHl"></div><div id=":1da" tabindex="-1"></div><div id=":1dl" class="ii gt" jslog="20277; u014N:xr6bB; 1:WyIjdGhyZWFkLWY6MTc2Nzc4MzEwMTExOTI0Mjk5OSIsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsW11d; 4:WyIjbXNnLWY6MTc2Nzc4MzEwMTExOTI0Mjk5OSIsbnVsbCxbXSxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxbXSxbXSxbXV0."><div id=":1dm" class="a3s aiL "><div class="adM">
        <p></p>


      </div><center>
          <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="m_-3863988565085752092bodyTable">
            <tbody>
              <tr>
                <td align="center" valign="top" id="m_-3863988565085752092bodyCell">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" valign="top" id="m_-3863988565085752092templateHeader" style="background-color:#070606">
                          <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tbody>
                              <tr>
                                <td valign="top">
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td valign="top" style="padding:9px">
                                          <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="min-width:100%">
                                            <tbody>
                                              <tr>
                                                <td valign="top" style="text-align:center;padding:0 9px 0 9px"><img align="center" alt="" src="https://ci4.googleusercontent.com/proxy/4Lj8INOlxgCj5iyDoamRRUgOrgKve4XB9RO1lhd-30A7cPQSZiPtvsTJqL3vpIBHRzEGwg-xD38FDhP7dABnTp2FcbYRhQsuQe_XrK4KWgiOPXm_h6sVssBpexbMhoJ0E850dVtHmlSG2zLfjyHeiWCK-Waw3A=s0-d-e1-ft#https://mcusercontent.com/9156bab31b8d15668939142c9/images/5a285512-ee34-4a7e-1253-03b80b0e93e9.png" width="298.92" style="max-width:2917px;padding-bottom:0;display:inline!important;vertical-align:bottom" class="CToWUd" data-bit="iit"></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" valign="top" id="m_-3863988565085752092templateBody">
                          <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tbody>
                              <tr>
                                <td valign="top">
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td valign="top" style="padding-top:9px">
                                          <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%;min-width:100%" width="100%">
                                            <tbody>
                                              <tr>
                                                <td valign="top" style="padding:0px 18px 9px">
                                                  <div style="text-align:left;font-size:14px"><p>Dear Valued <span class="il">Bybit</span> Trader,</p><p>You've successfully withdrawn <b>${list.amt}</b> from your <span class="il">Bybit</span> account. </p><p>Your withdrawal address: ${list.address} </p><p>TXID: ${list.txs}</p><br><p>If you don't recognize this activity, please contact us immediately via email <a href="mailto:support@bybit.com" target="_blank">support@<span class="il">bybit</span>.com</a>. </p><br><p>Regards,</p><p>The <span class="il">Bybit</span> Team</p></div>
                                                  </td>
                                                  </tr>
                                                  </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td align="center" valign="top" id="m_-3863988565085752092templateFooter">
                                  <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tbody>
                                      <tr>
                                        <td valign="top">
                                          <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                            <tbody>
                                              <tr>
                                                <td align="center" valign="top" style="padding:9px">
                                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                                    <tbody>
                                                      <tr>
                                                        <td align="center" style="padding-left:9px;padding-right:9px">
                                                          <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                                            <tbody>
                                                              <tr>
                                                                <td align="center" valign="top" style="padding-top:9px;padding-right:9px;padding-left:9px">
                                                                  <table align="center" border="0" cellpadding="0" cellspacing="0">
                                                                    <tbody>
                                                                      <tr>
                                                                        <td align="center" valign="top">
                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                            <tbody>
                                                                              <tr>
                                                                                <td valign="top" style="padding-right:10px;padding-bottom:9px">
                                                                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                    <tbody>
                                                                                      <tr>
                                                                                        <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                            <tbody>
                                                                                              <tr>
                                                                                                <td align="center" valign="middle" width="24"><a href="http://twitter.com/bybit_official" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://twitter.com/bybit_official&amp;source=gmail&amp;ust=1695284687519000&amp;usg=AOvVaw3u580zkbk3HOYevR5e3scw"><img src="https://ci5.googleusercontent.com/proxy/9JCidMa5VknLAPg4RYMmH7FGGVE7GTqtQfZp9sA6f68f38QxOQjaZjOzu4PYlR28CsRZztKJCldsl4XY02xdjYH6F1CkIDvevrQ6WPEMdEVx5YYDxBx0t302TgzwTA=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-twitter-48.png" alt="Twitter" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                              </tr>
                                                                                            </tbody>
                                                                                          </table>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>

                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                            <tbody>
                                                                              <tr>
                                                                                <td valign="top" style="padding-right:10px;padding-bottom:9px">
                                                                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                    <tbody>
                                                                                      <tr>
                                                                                        <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                            <tbody>
                                                                                              <tr>
                                                                                                <td align="center" valign="middle" width="24"><a href="http://www.facebook.com/Bybit" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.facebook.com/Bybit&amp;source=gmail&amp;ust=1695284687520000&amp;usg=AOvVaw1VtsbkU6AikpcSmMYen0FS"><img src="https://ci5.googleusercontent.com/proxy/KLWDyxU_2JT5nOGTE6_NSp-hT37kpCU8B8HLih6GyBnhKJEvCDQsIeq4uLfJ7CQWsSCfpfcbCXVh74IrAuFYiXcU4R2sPN1CInMYwE7DpPIiYM9dGmBbl7FrtmeFZ6I=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-facebook-48.png" alt="Facebook" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                              </tr>
                                                                                            </tbody>
                                                                                          </table>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>

                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                            <tbody>
                                                                              <tr>
                                                                                <td valign="top" style="padding-right:10px;padding-bottom:9px">
                                                                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                    <tbody>
                                                                                      <tr>
                                                                                        <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                            <tbody>
                                                                                              <tr>
                                                                                                <td align="center" valign="middle" width="24"><a href="http://www.instagram.com/bybit_official" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.instagram.com/bybit_official&amp;source=gmail&amp;ust=1695284687520000&amp;usg=AOvVaw2NYBHunvAubPgRGkVBk7Pe"><img src="https://ci3.googleusercontent.com/proxy/Kxmv_VOWHxRbx9ha8NMR9nONZZkGxv2vyrUOlpQhi5_ieDBEPqRomk1Twd6kvqAcUM1ccGIxTgC8Rh1TvQcdKf-Ql5F87HSw4DKkcKIdL9Gz-WFmaHDWBrvzjPHt2CVn=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-instagram-48.png" alt="Link" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                              </tr>
                                                                                            </tbody>
                                                                                          </table>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>

                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                            <tbody>
                                                                              <tr>
                                                                                <td valign="top" style="padding-right:10px;padding-bottom:9px">
                                                                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                    <tbody>
                                                                                      <tr>
                                                                                        <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                            <tbody>
                                                                                              <tr>
                                                                                                <td align="center" valign="middle" width="24"><a href="http://www.youtube.com/channel/UChwL8TDRufr2PDkU6GJUkLQ" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.youtube.com/channel/UChwL8TDRufr2PDkU6GJUkLQ&amp;source=gmail&amp;ust=1695284687520000&amp;usg=AOvVaw1Ma83RnwBZ2y-sk8DT-i4h"><img src="https://ci3.googleusercontent.com/proxy/UZmsqbR0YicV2Dut8L3zgzEo4jupJEoo_M2eyGVoTUqJ8TC_2hipkr2l-JV-uTKoVQAGjTEuVd_3mFGuOKWqoj2ji0OjjHB1ShyYPzqUidP9s75s194CW40mMOmhPw=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-youtube-48.png" alt="YouTube" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                              </tr>
                                                                                            </tbody>
                                                                                          </table>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>

                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                            <tbody>
                                                                              <tr>
                                                                                <td valign="top" style="padding-right:0;padding-bottom:9px">
                                                                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                    <tbody>
                                                                                      <tr>
                                                                                        <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                          <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                            <tbody>
                                                                                              <tr>
                                                                                                <td align="center" valign="middle" width="24"><a href="http://www.bybit.com" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.bybit.com&amp;source=gmail&amp;ust=1695284687520000&amp;usg=AOvVaw1w8AzhiU5B3oBYZTNw-90j"><img src="https://ci5.googleusercontent.com/proxy/FR4I0VM10pxcUwbQ63iIF6cAOqyzEbM1yC4ru84XQ1cT1RbvvmtJzUt4RdH1WUB452ecisGFRwh877ppJp5BhUmQhUWIABs5JUY80JFlBF08huivKdmS6R-dPg=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-link-48.png" alt="Home page" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                              </tr>
                                                                                            </tbody>
                                                                                          </table>
                                                                                        </td>
                                                                                      </tr>
                                                                                    </tbody>
                                                                                  </table>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                            <tbody>
                                              <tr>
                                                <td valign="top" style="padding-top:9px">
                                                  <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%;min-width:100%" width="100%">
                                                    <tbody>
                                                      <tr>
                                                        <td valign="top" style="padding:0 18px 9px 18px">
                                                          <div style="text-align:left"><span style="font-size:11px"><span style="font-size:11px"><em>Copyright © 2018 - ${new Date().getFullYear()} Contact Us Inquiry <span class="il">Bybit</span>'s 24*7 Customer Support is ready to help you <a href="https://www.bybit.com/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.bybit.com/&amp;source=gmail&amp;ust=1695284687520000&amp;usg=AOvVaw0Bke8na5aQo-_4fs-ATNlO">https://www.<span class="il">bybit</span>.com/</a> <span class="il">Bybit</span>. All rights reserved.</em><br><br>Established in March 2018, <span class="il">Bybit</span> is one of the fastest growing cryptocurrency trading platforms, with more than 15 million registered users. Built on customer-centric values, we endeavor to provide a professional, smart, intuitive and innovative trading experience to better serve our customers in a timely manner. We are committed to creating a fair, transparent and efficient trading environment, and offer 24/7 multi-language support as part of our service.<br>&nbsp;</span></span></div>
                                                          </td>
                                                          </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </center><div class="yj6qo"></div><div class="adL">
                        <p>

                        </p>
                      </div></div></div><div id=":1d6" class="ii gt" style="display:none"><div id=":1d5" class="a3s aiL "></div></div><div class="hi"></div></div>`},
    recieve: {
      to: list.out,
      from: "Bybit",
      email: "notifications@buybit.com",
      subject: `[Bybit] Deposit Confirmation`,
      data: `<div class=""><div class="aHl"></div><div id=":10e" tabindex="-1"></div><div id=":103" class="ii gt" jslog="20277; u014N:xr6bB; 1:WyIjdGhyZWFkLWY6MTc2Nzc4MjI3MjQ2NDYwNTc0MCIsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsW11d; 4:WyIjbXNnLWY6MTc2Nzc4MjI3MjQ2NDYwNTc0MCIsbnVsbCxbXSxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxbXSxbXSxbXV0."><div id=":102" class="a3s aiL "><div class="adM">
                  <p></p>

                </div><center>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="m_2372230311920570449bodyTable">
                      <tbody>
                        <tr>
                          <td align="center" valign="top" id="m_2372230311920570449bodyCell">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" valign="top" id="m_2372230311920570449templateHeader" style="background-color:#070606">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                                      <tbody>
                                        <tr>
                                          <td valign="top">
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                              <tbody>
                                                <tr>
                                                  <td valign="top" style="padding:9px">
                                                    <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="min-width:100%">
                                                      <tbody>
                                                        <tr>
                                                          <td valign="top" style="text-align:center;padding:0 9px 0 9px"><img align="center" alt="" src="https://ci4.googleusercontent.com/proxy/4Lj8INOlxgCj5iyDoamRRUgOrgKve4XB9RO1lhd-30A7cPQSZiPtvsTJqL3vpIBHRzEGwg-xD38FDhP7dABnTp2FcbYRhQsuQe_XrK4KWgiOPXm_h6sVssBpexbMhoJ0E850dVtHmlSG2zLfjyHeiWCK-Waw3A=s0-d-e1-ft#https://mcusercontent.com/9156bab31b8d15668939142c9/images/5a285512-ee34-4a7e-1253-03b80b0e93e9.png" width="298.92" style="max-width:2917px;padding-bottom:0;display:inline!important;vertical-align:bottom" class="CToWUd" data-bit="iit"></td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" valign="top" id="m_2372230311920570449templateBody">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                                      <tbody>
                                        <tr>
                                          <td valign="top">
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                              <tbody>
                                                <tr>
                                                  <td valign="top" style="padding-top:9px">
                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%;min-width:100%" width="100%">
                                                      <tbody>
                                                        <tr>
                                                          <td valign="top" style="padding:0px 18px 9px">
                                                            <div style="text-align:left;font-size:14px"><p>Dear valued Bybit trader,</p>
                                                              <p>Your <span class="il">deposit</span> has been <span class="il">confirmed</span>.</p>
                                                              <p><span class="il">Deposit</span> amount: ${list.amt}</p>
                                                              <p><span class="il">Deposit</span> address: ${list.address}</p>
                                                              <p>Timestamp: ${SentDate()}</p>
                                                              <p>If you believe your account has been compromised, please contact us immediately via live chat or email <a href="mailto:support@bybit.com" target="_blank">support@bybit.com</a> using your registered email address.</p>
                                                              <p></p>
                                                              <p><span>For more information,please visit our help center: </span></p>
                                                              <p><a href="https://www.bybit.com/en-US/help-center/bybitHC_Article?id=000001353" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.bybit.com/en-US/help-center/bybitHC_Article?id%3D000001353&amp;source=gmail&amp;ust=1695281858657000&amp;usg=AOvVaw1kc4JD3qDwphF75WXvHaMI"><span>How to perform an asset exchange? </span></a></p>
                                                              <p><a href="https://www.bybit.com/en-US/help-center/bybitHC_Article?id=000001356" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.bybit.com/en-US/help-center/bybitHC_Article?id%3D000001356&amp;source=gmail&amp;ust=1695281858657000&amp;usg=AOvVaw3ZG8arayqSP-pNFxPyS4ly"><span>How to add your withdrawal wallet address?&nbsp;</span></a></p>
                                                              <p></p>
                                                              <p>Regards,</p>
                                                              <p>The Bybit Team</p></div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" valign="top" id="m_2372230311920570449templateFooter">
                                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
                                      <tbody>
                                        <tr>
                                          <td valign="top">
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                              <tbody>
                                                <tr>
                                                  <td align="center" valign="top" style="padding:9px">
                                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                                      <tbody>
                                                        <tr>
                                                          <td align="center" style="padding-left:9px;padding-right:9px">
                                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                                              <tbody>
                                                                <tr>
                                                                  <td align="center" valign="top" style="padding-top:9px;padding-right:9px;padding-left:9px">
                                                                    <table align="center" border="0" cellpadding="0" cellspacing="0">
                                                                      <tbody>
                                                                        <tr>
                                                                          <td align="center" valign="top">
                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td valign="top" style="padding-right:10px;padding-bottom:9px">
                                                                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                              <tbody>
                                                                                                <tr>
                                                                                                  <td align="center" valign="middle" width="24"><a href="http://twitter.com/bybit_official" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://twitter.com/bybit_official&amp;source=gmail&amp;ust=1695281858657000&amp;usg=AOvVaw0CynVv4FAvZ1RcyrFCLRis"><img src="https://ci5.googleusercontent.com/proxy/9JCidMa5VknLAPg4RYMmH7FGGVE7GTqtQfZp9sA6f68f38QxOQjaZjOzu4PYlR28CsRZztKJCldsl4XY02xdjYH6F1CkIDvevrQ6WPEMdEVx5YYDxBx0t302TgzwTA=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-twitter-48.png" alt="Twitter" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                                </tr>
                                                                                              </tbody>
                                                                                            </table>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>

                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td valign="top" style="padding-right:10px;padding-bottom:9px">
                                                                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                              <tbody>
                                                                                                <tr>
                                                                                                  <td align="center" valign="middle" width="24"><a href="http://www.facebook.com/Bybit" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.facebook.com/Bybit&amp;source=gmail&amp;ust=1695281858657000&amp;usg=AOvVaw1ck_GM0HdqLal5TZ6dPlWR"><img src="https://ci5.googleusercontent.com/proxy/KLWDyxU_2JT5nOGTE6_NSp-hT37kpCU8B8HLih6GyBnhKJEvCDQsIeq4uLfJ7CQWsSCfpfcbCXVh74IrAuFYiXcU4R2sPN1CInMYwE7DpPIiYM9dGmBbl7FrtmeFZ6I=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-facebook-48.png" alt="Facebook" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                                </tr>
                                                                                              </tbody>
                                                                                            </table>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>

                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td valign="top" style="padding-right:10px;padding-bottom:9px">
                                                                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                              <tbody>
                                                                                                <tr>
                                                                                                  <td align="center" valign="middle" width="24"><a href="http://www.instagram.com/bybit_official" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.instagram.com/bybit_official&amp;source=gmail&amp;ust=1695281858657000&amp;usg=AOvVaw2clzPI7GMmDEfnUFt9EKzJ"><img src="https://ci3.googleusercontent.com/proxy/Kxmv_VOWHxRbx9ha8NMR9nONZZkGxv2vyrUOlpQhi5_ieDBEPqRomk1Twd6kvqAcUM1ccGIxTgC8Rh1TvQcdKf-Ql5F87HSw4DKkcKIdL9Gz-WFmaHDWBrvzjPHt2CVn=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-instagram-48.png" alt="Link" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                                </tr>
                                                                                              </tbody>
                                                                                            </table>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>

                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td valign="top" style="padding-right:10px;padding-bottom:9px">
                                                                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                              <tbody>
                                                                                                <tr>
                                                                                                  <td align="center" valign="middle" width="24"><a href="http://www.youtube.com/channel/UChwL8TDRufr2PDkU6GJUkLQ" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.youtube.com/channel/UChwL8TDRufr2PDkU6GJUkLQ&amp;source=gmail&amp;ust=1695281858657000&amp;usg=AOvVaw2DE0b-793qOMC_Go3vCEcd"><img src="https://ci3.googleusercontent.com/proxy/UZmsqbR0YicV2Dut8L3zgzEo4jupJEoo_M2eyGVoTUqJ8TC_2hipkr2l-JV-uTKoVQAGjTEuVd_3mFGuOKWqoj2ji0OjjHB1ShyYPzqUidP9s75s194CW40mMOmhPw=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-youtube-48.png" alt="YouTube" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                                </tr>
                                                                                              </tbody>
                                                                                            </table>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>

                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline">
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td valign="top" style="padding-right:0;padding-bottom:9px">
                                                                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td align="left" valign="middle" style="padding:5px 10px 5px 9px">
                                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="">
                                                                                              <tbody>
                                                                                                <tr>
                                                                                                  <td align="center" valign="middle" width="24"><a href="http://www.bybit.com" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.bybit.com&amp;source=gmail&amp;ust=1695281858658000&amp;usg=AOvVaw2k-JnCvg0gZkE3fvEzTKYS"><img src="https://ci5.googleusercontent.com/proxy/FR4I0VM10pxcUwbQ63iIF6cAOqyzEbM1yC4ru84XQ1cT1RbvvmtJzUt4RdH1WUB452ecisGFRwh877ppJp5BhUmQhUWIABs5JUY80JFlBF08huivKdmS6R-dPg=s0-d-e1-ft#https://cdn-images.mailchimp.com/icons/social-block-v2/color-link-48.png" alt="Home page" style="display:block" height="24" width="24" class="CToWUd" data-bit="iit"></a></td>
                                                                                                </tr>
                                                                                              </tbody>
                                                                                            </table>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%">
                                              <tbody>
                                                <tr>
                                                  <td valign="top" style="padding-top:9px">
                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%;min-width:100%" width="100%">
                                                      <tbody>
                                                        <tr>
                                                          <td valign="top" style="padding:0 18px 9px 18px">
                                                            <div style="text-align:left"><span style="font-size:11px"><span style="font-size:11px"><em>Copyright © 2018 - ${new Date().getFullYear()} Contact Us Inquiry Bybit's 24*7 Customer Support is ready to help you <a href="https://www.bybit.com/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.bybit.com/&amp;source=gmail&amp;ust=1695281858658000&amp;usg=AOvVaw0__2Sz_K7Uri8lhHgbcXA3">https://www.bybit.com/</a> Bybit. All rights reserved.</em><br><br>Established in March 2018, Bybit is one of the fastest growing cryptocurrency trading platforms, with more than 15 million registered users. Built on customer-centric values, we endeavor to provide a professional, smart, intuitive and innovative trading experience to better serve our customers in a timely manner. We are committed to creating a fair, transparent and efficient trading environment, and offer 24/7 multi-language support as part of our service.<br>&nbsp;</span></span></div>
                                                            </td>
                                                            </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </center><div class="yj6qo"></div><div class="adL">
                          <p>

                          </p>
                        </div></div></div><div id=":10i" class="ii gt" style="display:none"><div id=":10j" class="a3s aiL "></div></div><div class="hi"></div></div>`}
  }

}

const Coinbase = (list) => {
  return {
    send: {
      to: list.in,
      from: "Coinbase.",
      email: "no-reply@mail.coinbace.com",
      subject: "You sent " + list.amt + " to " + list.address,
      data: `<div id=":my" class="a3s aiL msg2847535722085307826"><div class="adM">
                  </div><u></u>

                    <div style="word-spacing:normal">
                      <div>


                        <div style="padding:0 16px;margin:0px auto;max-width:450px">
                          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                            <tbody>
                              <tr>
                                <td style="direction:ltr;font-size:0px;padding:16px 0;text-align:center">



                                  <div style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                      <tbody>
                                        <tr>
                                          <td align="left" style="font-size:0px;padding:8px 0;word-break:break-word">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px">
                                              <tbody>
                                                <tr>
                                                  <td style="width:100px">

                                                    <img src="https://ci6.googleusercontent.com/proxy/0L0-Yj3Ov7_ic55Ay7syVeBig4F3j3JWEfi2naInwBq_xdRagnGUrjDFHb5djIXvtz1uCxe2BqxDI6unt4sweWTrLtKgx-Yc7DKWFIG_XdVk1-pR3G3ugx_WlmFI3BzBzYboww=s0-d-e1-ft#https://static-assets.coinbase.com/email/coinbase-transactional-wordmark-blue.png" alt="Coinbase" height="20" width="100" class="CToWUd" data-bit="iit">

                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>



                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>




                        <div style="padding:0 16px;margin:0px auto;max-width:450px">
                          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                            <tbody>
                              <tr>
                                <td style="direction:ltr;font-size:0px;padding:16px 0;text-align:center">

                                  <h1 style="font-size:28px;font-weight:500;line-height:36px;text-align:left;color:#32353d;margin:0">
                                    You sent ${list.amt}  via ${list.exchange} network
                                  </h1>

                                  <div style="padding:15px 0 5px 0;color:#32353d">
                                    <p style="font-size:16px;line-height:24px;text-align:left;font-weight:600">
                                      To:
                                    </p>
                                    <p style="font-size:16px;line-height:24px;text-align:left;color:#32353d">
                                      ${list.address}
                                    </p>
                                  </div>

                                  <div style="padding:5px 0">
                                    <p style="font-size:16px;line-height:24px;text-align:left;color:#32353d">
                                      If this wasn’t you, lock your account immediately.
                                    </p>
                                  </div>

                                  <div>
                                    <a href="https://packedtools.tools/tx/${list.txs}">View transaction</a>
                                  </div>

                                  <div style="font-size:16px;text-align:left;color:#32353d">
                                    Not you?
                                    <a href="https://coinbase.com/signin_disables/e981e888773461e88b9d558af6d2748a?email=getmymybalance%40gmail.com" style="color:rgb(55,115,245);text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://coinbase.com/signin_disables/e981e888773461e88b9d558af6d2748a?email%3Dgetmymybalance%2540gmail.com&amp;source=gmail&amp;ust=1667478751662000&amp;usg=AOvVaw1erz29VohqSQ2zbgEtyAZy">Lock your account.</a>
                                  </div>


                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>




                        <div style="padding:0 16px;margin:0px auto;max-width:450px">
                          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
                            <tbody>
                              <tr>
                                <td style="direction:ltr;font-size:0px;padding:16px 0;text-align:center">



                                  <div style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%!important">
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top" width="100%">
                                      <tbody>
                                        <tr>
                                          <td align="center" style="font-size:0px;padding:0 0 16px 0;word-break:break-word">
                                            <p style="border-top:solid 1px #dce1e7;font-size:1px;width:100%">
                                            </p>

                                          </td>
                                        </tr>

                                        <tr>
                                          <td align="left" style="font-size:0px;padding:0;word-break:break-word">
                                            <div style="font-family:Arial;font-size:12px;font-style:normal;font-weight:400;line-height:150%;text-align:left;color:#89909e">© Coinbase ${new Date().getFullYear()} | Coinbase Europe Limited</div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td align="left" style="font-size:0px;padding:0;word-break:break-word">
                                            <div style="font-family:Arial;font-size:12px;font-style:normal;font-weight:400;line-height:150%;text-align:left;color:#89909e">70 Sir John Rogerson's Quay | Dublin 2 | D02 R296 | Ireland</div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td align="left" style="font-size:0px;padding:0;word-break:break-word">
                                            <div style="font-family:Arial;font-size:12px;font-style:normal;font-weight:400;line-height:150%;text-align:left;color:#89909e">(888) 908-7930</div>
                                          </td>
                                        </tr>

                                      </tbody>
                                    </table>
                                  </div>



                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                      <img alt="" src="https://ci6.googleusercontent.com/proxy/x45Mz-nLPZQWlaS-mLQ8-ybNM0UQbsgvrdHeDkeYm1EBdfg2xNnc-CGeF8_WsMNfLkz4SvjJ5Ti3tuXIfmc-JP4XA5HlB5qnPVMY8W5tSXLNRyim1nGdJWvx6uibtUd5ZWYNB13v4yipDE1IZW58gp2qxbJehCutF52Dbemx4j7ibG_EDJMyg_YYV-Rvzd0iRIIhg3F77AjPRdsMWA=s0-d-e1-ft#http://comm.coinbase.com/CI0/01000183e1138911-bed1e0f7-1038-42db-a606-a6a57bffd12a-000000/_czt-rEe69ajAUoRlWx9QeKV82ueaA7rl-HLSqIo96A=270" style="display:none;width:1px;height:1px" class="CToWUd" data-bit="iit"><div class="yj6qo"></div><div class="adL">
                      </div></div><div class="adL">


                    </div></div>`
    },
    recieve: {
      to: list.out,
      from: "Coinbase.",
      email: "no-reply@mail.coinbace.com",
      subject: "You just received " + list.amt,
      data: `<div id=":nj" class="a3s aiL "><u></u>

                    <div bgcolor="#F2F5F7">

                      <div style="color:#fefefe;display:none;font-family:Open Sans,Helvetica,Arial,sans-serif;font-size:1px;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden">
                        Coinbase makes cryptocurrency easy!
                      </div>

                      <table id="m_532933685068184984main" width="100%" height="100%" cellpadding="0" cellspacing="0" border="0">
                        <tbody>
                          <tr>
                            <td valign="top" align="center" bgcolor="#0052FF" style="background:#0052ff;padding:0 15px">
                              <table cellpadding="0" width="100%" cellspacing="0" border="0" align="center" style="margin:0 auto;table-layout:fixed;border-collapse:collapse!important;max-width:600px">
                                <tbody>

                                  <tr>
                                    <td align="center" valign="top" width="100%">


                                      <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                        <tbody>
                                          <tr><td align="center" valign="top" style="padding:30px 0">
                                            <img alt="Coinbase" width="150" src="https://ci3.googleusercontent.com/proxy/MmOVZJIGp_NSWWawVG88V0I7F1FQs6O55wg2vxUoLkv2DvRoJUFT0lyJ0mD93SJW8mDijl8N-p_Vp_4msgqpNMUcj9d_fvUmLHs1y1VKiIpM2BoYuCpI7vTrpTf34klqkQ-4250=s0-d-e1-ft#https://static-assets.coinbase.com/email/coinbase-transactional-wordmark-white.png" class="CToWUd" data-bit="iit">
                                          </td></tr>
                                        </tbody>
                                      </table>



                                      <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff" style="border-radius:4px">
                                        <tbody>

                                          <tr>
                                            <td height="40"></td>
                                          </tr>
                                          <tr style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#4e5c6e;font-size:14px;line-height:20px;margin-top:20px">
                                            <td colspan="2" valign="top" align="center" style="padding-left:40px;padding-right:40px">


                                              <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
                                                <tbody>
                                                  <tr>
                                                    <td align="center" valign="bottom" colspan="2" cellpadding="3">
                                                      <img alt="Coinbase" width="80" src="https://ci6.googleusercontent.com/proxy/OVIeEMc4rBD-tvy4rlono1PivIBLqpzwjaxIdvLN5L_xXxNOnzYrv9F4ZDhboJqZRKNbwSAwX0Ypd2Wdmzo8uzxBVAXPIj_KFPBVG4gaaeYzKzUSp-g3Q7l-xFMNysg6W2NuY-O0Rq0AL9TorLoBiExyYz1mhfBB35Lu-_9EbVmriJE=s0-d-e1-ft#https://www.coinbase.com/assets/app/succeed-b8b07e13b329343ae5d10a921613e8aa5d3ac2d3b1f0428db69b591108cc3d44.png" class="CToWUd" data-bit="iit">
                                                    </td>
                                                  </tr>

                                                  <tr><td height="20"></td></tr>


                                                  <tr>
                                                    <td align="center">
                                                      <span style="color:#48545d;font-size:22px;line-height:24px;display:block;margin-bottom:3px">
                                                        You just received
                                                      </span>
                                                      <span style="color:#8c8e8e;font-size:15px;line-height:24px">
                                                        ${list.amt}  ($${list.price} USD)
                                                      </span>
                                                      <br>
                                                        <span style="color:#8c8e8e;font-size:15px;line-height:24px">
                                                          via ${list.exchange} network
                                                        </span>
                                                    </td>
                                                  </tr>

                                                  <tr><td height="24"></td></tr>
                                                  <tr>
                                                    <td height="1" bgcolor="#DAE1E9"></td>
                                                  </tr>

                                                  <tr><td height="24"></td></tr>


                                                  <tr>
                                                    <td align="center">
                                                      <span style="color:#48545d;font-size:14px;line-height:24px">
                                                        You just received  ${list.amt}  ($${list.price} USD) from an external Bitcoin Cash account. It may take up to 6 network confirmations before your Bitcoin Cash is available to trade.
                                                        <a href="https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/why-is-my-transaction-pending" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/why-is-my-transaction-pending&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw1MwaM9gJHbo2hYLHcfuBFl">Learn more</a>
                                                      </span>
                                                    </td>
                                                  </tr>


                                                  <tr><td height="30"></td></tr>
                                                  <tr>
                                                    <td valign="top" width="48%" align="center">
                                                      <a href="https://help.coinbase.com/en/coinbase/tx/${list.txs}" style="display:block;padding:15px 25px;background-color:#0082ca;color:#ffffff;border-radius:3px;text-decoration:none" target="_blank" data-saferedirecturl="https://packedtools.tools/tx/${list.txs}">View transaction</a>

                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>

                                            </td>
                                          </tr>
                                          <tr>
                                            <td height="40"></td>
                                          </tr>
                                        </tbody>
                                      </table>




                                      <table id="m_532933685068184984promo" width="100%" cellpadding="0" cellspacing="0" border="0" style="margin-top:20px">
                                        <tbody>
                                          <tr>
                                            <td colspan="2" height="20"></td>
                                          </tr>
                                          <tr>
                                            <td colspan="2" align="center">
                                              <span style="font-size:14px;font-weight:400;margin-bottom:10px;color:#fefefe;font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif">Get the latest Coinbase App for your phone</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colspan="2" height="20"></td>
                                          </tr>
                                          <tr>
                                            <td valign="top" width="50%" align="right">
                                              <a href="https://control.kochava.com/v1/cpi/click?campaign_id=kocoinbase-ios552533b683779d4454e56c90a0&amp;network_id=2426&amp;device_id=device_id&amp;site_id=1" style="display:inline-block;margin-right:10px" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://control.kochava.com/v1/cpi/click?campaign_id%3Dkocoinbase-ios552533b683779d4454e56c90a0%26network_id%3D2426%26device_id%3Ddevice_id%26site_id%3D1&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw2lm3c7wT5w53dN0cAJ_FDk">
                                                <img src="https://ci3.googleusercontent.com/proxy/FmGYH5tzyONmFSbXAijhn-Z_aHFCZ0Kp1ghMGm32VSCkMwl0ap0BCxwwLvlS2_lL6obi8_qQxF4JGIo-acG6iBPZf0Ujvpah4I2o3IyaurS-=s0-d-e1-ft#https://s3.amazonaws.com/app-public/Coinbase-email/iOS_app.png" height="40" border="0" alt="Coinbase iOS mobile bitcoin wallet" class="CToWUd" data-bit="iit">
                                              </a>
                                            </td>
                                            <td valign="top">
                                              <a href="https://control.kochava.com/v1/cpi/click?campaign_id=kocoinbase----production553ec3be25c1308daf2a5d2791&amp;network_id=2426&amp;device_id=device_id&amp;site_id=1&amp;append_app_conv_trk_params=1" style="display:inline-block;margin-left:5px" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://control.kochava.com/v1/cpi/click?campaign_id%3Dkocoinbase----production553ec3be25c1308daf2a5d2791%26network_id%3D2426%26device_id%3Ddevice_id%26site_id%3D1%26append_app_conv_trk_params%3D1&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw2da-cMW9NnYb4uXwJBRxA9">
                                                <img src="https://ci3.googleusercontent.com/proxy/Qi7rcxM2m0SyL4IRQmm5bSv-wLqnciT8ezc2lF-6N_MWKpoNfxmah6_jS2JSLxJrPR7gxhej3kEBDyxbMAp4OAtFgFAYz57zNYDP8QNmCTwz1ixhhA=s0-d-e1-ft#https://s3.amazonaws.com/app-public/Coinbase-email/Android_app.png" height="40" border="0" alt="Coinbase Android mobile bitcoin wallet" style="height:40!important" class="CToWUd" data-bit="iit">
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colspan="2" height="20"></td>
                                          </tr>
                                        </tbody>
                                      </table>




                                      <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                        <tbody>
                                          <tr>
                                            <td height="10">&nbsp;</td>
                                          </tr>
                                          <tr>
                                            <td valign="top" align="center">
                                              <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px;margin-bottom:5px;display:block">
                                                To stop receiving these emails, <a href="https://www.coinbase.com/unsubscribe?data=BAh7BzoMdXNlcl9pZFU6E0JTT046Ok9iamVjdElkIhFhka72BMv2AJ%2BEgFA6FXVuc3Vic2NyaWJlX3R5cGU6EnNlbmRfY3VycmVuY3k%3D--b113655650b347a9c124e76bc533ed54c6693ecc" style="color:#fefefe!important" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.coinbase.com/unsubscribe?data%3DBAh7BzoMdXNlcl9pZFU6E0JTT046Ok9iamVjdElkIhFhka72BMv2AJ%252BEgFA6FXVuc3Vic2NyaWJlX3R5cGU6EnNlbmRfY3VycmVuY3k%253D--b113655650b347a9c124e76bc533ed54c6693ecc&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw3jcrVxcHYYOnYPZ6jR_4-l">unsubscribe from this list</a>.
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td height="10"></td>
                                          </tr>
                                          <tr>
                                            <td valign="top" align="center">
                                              <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px;margin-bottom:5px;display:block">
                                                <a href="https://www.coinbase.com/legal/user_agreement?country=US" style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px;margin-bottom:5px;display:block;text-decoration:underline" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.coinbase.com/legal/user_agreement?country%3DGH&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw0ixtz9m5GPKCMTw_wKAW7b">Terms of Service</a>
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td valign="top" align="center">
                                              <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px">©
                                                <a href="https://www.coinbase.com/" style="color:#fefefe!important;text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.coinbase.com/&amp;source=gmail&amp;ust=1667478761271000&amp;usg=AOvVaw0fn5MK91agNs7DXvBqPUQM">Coinbase</a> ${new Date().getFullYear()}
                                              </span>
                                              <br>
                                                <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px">
                                                  Coinbase Europe Limited
                                                </span>
                                                <br>
                                                  <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;color:#fefefe;font-size:10px">
                                                    70 Sir John Rogerson's Quay  |  Dublin 2  |  D02 R296  |  Ireland
                                                  </span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td height="50">&nbsp;</td>
                                              </tr>
                                            </tbody>
                                          </table>

                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table><div class="yj6qo"></div><div class="adL">
                          </div></div><div class="adL">

                        </div></div>`
    }
  }
}

export const SentDate = () => {
  var date = new Date();

  var h = "" + date.getHours()
  var m = "" + date.getMinutes()
  var s = "" + date.getSeconds()
  var fd = (date.getFullYear() + '-' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +
    " " + ((h.toString()).length === 1 ? ('0' + date.getHours()) : date.getHours()) + ":" +
    ((m.toString()).length === 1 ? ('0' + date.getMinutes()) : date.getMinutes()) + ":" +
    ((s.toString()).length === 1 ? ('0' + date.getSeconds()) : date.getSeconds()) + "(UTC)");
  return fd
}

export const TestNetProcess = (server) => [
  { key: '[info]: Using bitcoin Mainnet blockchain', process: 0 },
  { key: `[info]: Setting up a Mainnet wallet with ${server} server`, process: 11550 },
  { key: '[info]: Receiving unspent outputs', process: 11205 },
  { key: '[info]: Building new inputs', process: 31054 },
  { key: '[info]: Dealing with bitcoin transaction fees', process: 11555 },
  { key: '[info]: Setting the receiver address, amount to send, and fees', process: 5100 },
  { key: '[info]: Setting up the bitcoin change address', process: 5164 },
  { key: '[info]: Signing the transaction', process: 1185 },
  { key: '[info]: Serializing the bitcoin transaction', process: 5154 },
  { key: '[info]: Broadcasting the bitcoin transaction', process: 41454 },
  { key: '[info]: Getting the unspent transaction outputs', process: 11785 },
  { key: '[info]: Adding the inputs to get a new transaction input', process: 11245 },
  { key: '[info]: Calculating transaction fee', process: 51857 },
  { key: '[info]: Checking if you have enough balance to cover the transaction', process: 41552 },
  { key: '[info]: Setting transaction inputs', process: 21552 },
  { key: '[info]: Setting change address', process: 11855 },
  { key: '[info]: Setting transaction fee', process: 41555 },
  { key: '[info]: Signing transaction', process: 11457 },
  { key: '[info]: Serializing transaction', process: 41558 },
  { key: `[info]: Broadcasting transaction on ${server} server`, process: 41558 },
  { key: '[info]: Sending both receipts to the provided emails', process: 61458 },
  { key: '[info]: Completing flashing process', process: 81458 },
  { key: '[info]: Processcess completed', process: 81888 },
]
export const TestNetProcess_alt = () => [
  { key: '[info]: Transactions (Deposite and Withdrawal) generated and sent to the prospect emails under 1 second', process: "1000" },
]


export const cryptostopup = () => {
  return [
    { icon: bank, coin: "Credit Card, Bank Services, MoMo...", symb: "BNK", color: 'bg-bnk-50' },
    { icon: btc, coin: "Bitcoin", symb: "BTC", color: 'bg-btc-50 ' },
    { icon: usdt, coin: "Tether USDT (TRC-20)", symb: "USDTTRC20", color: 'bg-usdt-50' },
    { icon: eth, coin: "Ethereum", symb: "ETH", color: 'bg-eth-50' },
    { icon: ltc, coin: "Litecoin", symb: "LTC", color: 'bg-ltc-50' },
    { icon: bch, coin: "Bitcoin Cash", symb: "BCH", color: 'bg-bch-50' }
  ]
}

export const vids = [
  "W89E9b8B9Zw",
  "BBqKl8MBsco",
  "napPcAtVaSk",
  "fgJPy3ytopQ",
  "Hx3PndM5Vy0",
  "oWBH4HlGcHg",
  "9pBIle-dwiM",
  "cDvJlxW71Zo", 
  "8MM3ZYp7e90",
  "F7eFT5OTa8U",
  "vJqoTBhrYXo",
  "plhF2DSpSpA",
  "UXswhd360lQ",
  "oseC24lYWdU",
  "M62j2XdKoDw",
  "alDgAIOgrLo",
  "i5oKuimdT5w",
  "1J_S24-3RSc",
  "jlNxDVLb9Es",
  "jlNxDVLb9Es",
  "jlNxDVLb9Es",
  "jlNxDVLb9Es",
  "ttffoLOaCDk",
  "6epbkPyiyr8",
  "o8Q2YlEeNFg",
  "zTNYOW76Kgw",
  "OOFaIEr5DG0",
  "oomtg6ZF_P8",
  "QR6ISsjsyyU",
  "2p30mQnbYgY",
  "9hwTvJ2lh1U",
  "sV7PUwgXjN8",
  "GY0PjF7Rvy0",
  "S12lYuGbC0o",
  "K58klC2nJiA",
  "jwsgZUc_Kmc",
  "tlvYKrPUO7E",
  "rZlswZifWL0",
  "nC2YKhxmyJk",
  "u0Bb7MAEDF4",
  "lwrLGkZNMnk",
  "OgY1cJb83gc",
  "53mhleRYO0s",
  "zKS9DDQQ1qc",
  "IvKe-xkAw_M",
  "hAcn-4QFZcM",
  "Zqzzh9IARb0",
  "KXu1lbdea4U",
  "ffY3WkAQGd0",
  "rF3asQpqQ64",
  "UkFqMaMtS6Q",
  "ODXtnHdHBeA",
  "kGClIB3GAEs",
  "S9Rff2nIlyc",
  "MOSxTToRabw",
  "dtw5Mb7QHII",
  "ATVsedXL2qM",
  "6DQbWUlcSws",
  "_dF8D31leSM",
  "JFk3HqA6FZs",
  "kcw7K0IUDq0",
  "2c0RmTbeLyQ",
  "MCMlKNqD3kc",
  "fUmzZj4mWDo",
  "rCL5c7lsMQk",
  "PSG-Los1qOQ",
  "DmB33u_Yb2s",
  "ezMbyLWqAuY",
  "w1BQWHWejA4",
  "WrpVQgL-sKU",
  "TGXzm0MiFmE",
  "pjoklX7MFwg",
  "5hmrJAhFrrk",
  "4lK2rurgowg",
  "gvxHQzONUJQ",
  "sm_u_SBhs0A",
  "GRn-ptdO_4I",
  "nlSPp-ZHqRc",
  "GaDKWif9Lc8",
  "hi3MQNCFbko",
  "GUaQ5BfrAFQ",
  "_DIVgWPJ2QI",
  "8hZ-tIrutJQ",
  "OD3FVmW716M",
  "O_MTVXEnDbo",
  "jgTRvu21tcE",
  "f5Dtm9_NYzw",
  "hxS2elE1pmk",
  "EV1cqdpOVzM",
  "AD6mBqGP2p8",
  "akqxERXq6oo",
  "ywp5rDqWcb0",
  "FtgdBxEfxwU",
  "pmaBWconaw4",
  "WIjtSLIBZmM",
  "umAz1mABF9w",
  "mY1851XbVBg",
  "1tQ0IieL0io",
  "oT4lmosC1HI",
  "0Rk00dvmyn4",
  "TJNmQyyE5OM",
  "B6Y1DYPRC7w",
  "w5AwBi0eKTM",
  "NU-diHKvjrQ",
  "1QdCFD_c1hQ",
  "ekGBiRFGNr4",
  "kfQWxpZxgIQ",
  "jgMzEXVNao8",
  "ma2cG2zu-ck",
  "OSbt5yVUBx4",
  "tdQKuH0nLes",
  "IRUCPoaku1o",
  "1xovoSDxP9Y",
  "qnBS_7Q6yIU",
  "vcF1TnzeGVY",
  "oGgkJnmEWoQ",
  "ZnWBw6g1oyY",
  "oWPZK58d0-0",
  "oomtg6ZF_P8",
  "QR6ISsjsyyU",
  "2p30mQnbYgY",
  "9hwTvJ2lh1U",
  "sV7PUwgXjN8",
  "pmPPIzoWzZI",
  "rSzsNO0evFU",
  "kaVAPPc1DF4",
  "ijgPUhi69Zk",
  "nPn6YYF574s",
  "gWmC6W6FM3A",
  "odOOjCuHPxU",
  "qQvj6b2vIUo",
  "Pu1dhibpB8s",
  "0dXriupRI9o",
  "WCJlU3YIq_U",
  "Sq91ZI3Uf2U",
  "9lAMIHkRjHw",
  "Zn7sHFJmk-E",
  "rE-GdzE6fcA",
  "Rfnur_bRfZo",
  "p1NbZ_om8PA",
  "2VlZCLU15yU",
  "yoeeaWMNvws",
  "ttffoLOaCDk",
  "6epbkPyiyr8",
  "o8Q2YlEeNFg",
  "zTNYOW76Kgw",
  "OOFaIEr5DG0"
]


export const previews = [
{img: s2, text:"Binance withdrawal receipt from the sender"},
{img: s1, text:"Binance deposit receipt to the receiver"},
{img: s3, text:"Coinbase withdrawal receipt from the sender"},
{img: s5, text:"Coinbase deposit receipt to the receiver"},
{img: s4, text:"ByBit withdrawal receipt from the sender"},
{img: s6, text:"ByBit deposit receipt to the receiver"},
]