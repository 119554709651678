
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
import { collection, getDoc,  query, doc, getDocs, setDoc, updateDoc, onSnapshot } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API,
    authDomain: process.env.REACT_APP_DOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGEID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app)
export const db = getFirestore(app)

export const UID = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}


export const SetADoc = async (col, id, list) => {
    return await setDoc(doc(db, col, id), list)
}

export const GetADoc = async (col, id) => {
    return await getDoc(doc(db, col, id))
}
export const UpdateADoc = async (col, id, list) => {
    return await updateDoc(doc(db, col, id), list)
}
export const GetADocWhere = async (col, condition) => {
    let arr = []
    const snapshot = await getDocs(query(collection(db, col), condition))
    snapshot.forEach((doc) => {
        arr.push(doc.data())
    })

    return arr;
}

export const Snapshots = async (col, uid) => {
    onSnapshot(doc(db, col, uid), async (doc) => {
        return await doc.data();
    })
}

export const Signout = async () => {
    await auth.signOut().then((v) => {
        localStorage.removeItem('user')
        window.location.reload()
    })
}