import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { FaSpinner } from "react-icons/fa";
import instance from "../config/classes";

const Progressdialog = ({ isProgress }) => {
  confirmAlert({
    title: "Confirm Dialog",
    overlayClassName: "overlay-custom-className-name",
    onClickOutside:false,

    customUI: ({ title, onClose }) => {
     instance.setOnClose(onClose);
      return (
        <div className="mx-8" id="prog">
          <div
            className="mx-auto p-4 max-w-md text-white rounded-lg bg-gray-800 w-full m-6 lg:w-max lg:h-max"
            style={{ zIndex: "99999" }}
          >
            <div className="flex justify-center p-4">
              <FaSpinner className="text-3xl text-white animate-spin" />
            </div>
 
            <p className="mb-3">Please wait as the page loads</p>
            <div className="flex gap-4 justify-end p-2">
              <button className="font-medium" onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

export default Progressdialog;
