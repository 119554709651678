import React, { useState, useLayoutEffect } from "react";
import {
    FaCheckCircle,
    FaCopy,
    FaEllipsisH,

    FaQuestionCircle,
    FaStopCircle,
} from "react-icons/fa";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { mainhome } from "../config/data";
import { Info } from "../config/functions";
import { GetPaymentStatus } from "./initpayments";
import { SetADoc } from "../config/db";
import moment from "moment/moment";
import YTVideos from "../components/yt";



const PaymentGateway2 = () => {
    const location = useLocation();
    const data = location.state.code;
    const email = location.state.email;
    const balance = location.state.bal;
    const plan = location.state.plan;
    /*   const em = location.state.useremail;
    const un = location.state.username; */
    const [payment, setpayment] = useState([]);
    const [pay, setpay] = useState([]);
    localStorage.setItem('code', data)

    const navigation = useNavigate();
    try {
        document.querySelector('.react-confirm-alert-overlay').style.display = "none";
    } catch (e) { }


    //useLayoutEffect(() => {}, [data, payment]);

    let x = setInterval(() => {

        if (localStorage.getItem('code') !== null) {
            const paycode = localStorage.getItem('code')
            GetPaymentStatus(paycode)
                .then((v) => v.text())
                .then(async (value) => {
                    clearInterval(x)

                    value && setpayment([JSON.parse(value)][0]);
                    value && setpay([JSON.parse(value)]);

                    if (value) {
                        if (payment.payment_status === "confirmed" ||
                            payment.payment_status === "partially_paid" ||
                            payment.payment_status === "finished") {

                            await SetADoc('transactionreceipt', email, {
                                amount: parseInt(payment.outcome_amount.toFixed()),
                                plan,
                                balance: 0,
                                counter: 0,
                                date: plan === "daily" ? moment().format("LLL") : moment().format("LL")
                            }).then((v) => {
                                clearInterval(x)
                                localStorage.removeItem('code')
                                navigation(mainhome, { replace: true });


                            });
                            return
                        }

                    }
                })
                .catch((err) => {
                    clearInterval(x)
                    //Info({ title: "Payment Error", msg: err.message });
                });
            return
        }
        GetPaymentStatus(data)
            .then((v) => v.text())
            .then(async (value) => {
                clearInterval(x)

                value && setpayment([JSON.parse(value)][0]);
                value && setpay([JSON.parse(value)]);

                if (value) {
                    if (payment.payment_status === "confirmed" ||
                        (payment.payment_status === "partially_paid" && payment.price_amount.toFixed(2) > 48.0) ||
                        payment.payment_status === "finished") {

                        await SetADoc('transactionreceipt', email, {
                            amount: parseInt(payment.outcome_amount.toFixed()),
                            plan,
                            balance: 0,
                            counter: 0,
                            date: plan === "daily" ? moment().format("LLL") : moment().format("LL")
                        }).then((v) => {
                            clearInterval(x)
                            localStorage.removeItem('code')
                            navigation(mainhome, { replace: true });
                        });
                    }
                }
            })
            .catch((err) => {
                clearInterval(x)
                //Info({ title: "Payment Error", msg: err.message });
            });
    }, 2000);


    return (
        <div className="flex lg:w-3/6 px-4 justify-center mx-auto my-auto">

            <div className="flex flex-col items-center mx-auto my-auto justify-center">
                <h1 className="font-extrabold text-2xl lg:text-4xl text-white my-4">
                    Complete Your Transaction
                </h1>
                <p className="text-center text-white">Do not close this window untill your payment has gone through or becomes successful </p>
                <div className="rounded-xl p-8 w-full flex flex-col bg-white mt-8">
                    {pay.length > 0 && (
                        <div className="flex flex-col justify-center max-w-screen-md">
                            <h1 className="font-bold text-2xl lg:text-4xl text-black-100 text-center">
                                Payment ID: {payment.payment_id}
                            </h1>
                            <h1 className="font-bold text-xl text-black-100 text-center">
                                Order: #{payment.order_id}
                            </h1>
                            <div className="my-8 flex flex-col lg:flex-row gap-4 justify-between">
                                <div className="flex justify-center lg:justify-end w-full lg:w-max lg:order-2">
                                    <QRCode
                                        size={120}
                                        value={payment.pay_address}
                                        fgColor={"#21A332"}
                                    />
                                </div>
                                <div className="w-full lg:4/6 lg:order-1">
                                    <p className="text-black-100 text-lg">
                                        <strong>Order price:</strong> ${payment.price_amount.toFixed(2)}
                                        {/*  {payment.price_currency} */}
                                    </p>
                                    <div className="flex gap-2 items-center">
                                        <p className="text-black-100  text-lg">
                                            <strong>Pay Amount: </strong>
                                            {payment.pay_currency === 'usdttrc20' && payment.pay_amount.toFixed(0) + '.00'}
                                            {payment.pay_currency !== 'usdttrc20' && payment.pay_amount.toFixed(4)}

                                        </p>{" "}<p className="text-green-500 cursor-pointer ">{payment.pay_currency}</p>{" "}
                                        <CopyToClipboard
                                            text={payment.pay_address.toString()}
                                            onCopy={() =>
                                                Info({
                                                    title: "Clipboard",
                                                    msg: `Pay Price ${payment.pay_currency === "usdttrc20" ? '$' : payment.pay_currency}${payment.pay_currency === "usdttrc20"
                                                        ? payment.pay_amount.toFixed(2)
                                                        : payment.pay_amount.toFixed(4)} Copied to clipbaord`,
                                                })
                                            }
                                        >
                                            <FaCopy
                                                className="text-green-500 cursor-pointer "
                                                size={20}
                                                onClick={() => { }}
                                            />
                                        </CopyToClipboard>
                                        {" "}
                                    </div>
                                    <div className="flex gap-2 max-w-screen-sm md:max-w-screen-md">
                                        <p className="text-lg text-black-100 ">
                                            <strong>Address:</strong>{" "}
                                            <span className="break-all">
                                                {payment.pay_address.toString()}
                                            </span>
                                        </p>
                                        <CopyToClipboard
                                            text={payment.pay_address.toString()}
                                            onCopy={() =>
                                                Info({
                                                    title: "Clipboard",
                                                    msg: `Address ${payment.pay_address.toString()} Copied to clipbord`,
                                                })
                                            }
                                        >
                                            <FaCopy
                                                className="text-green-500 cursor-pointer "
                                                size={20}
                                                onClick={() => { }}
                                            />
                                        </CopyToClipboard>

                                    </div>
                                    <h1 className="text-green-500 mt-4 w-full">
                                        {payment.order_description}
                                    </h1>
                                </div>
                            </div>
                            <div className="flex h-0.5 mx-auto bg-green-500 w-5/6 my-4"></div>
                            <div className="flex flex-col gap-2  text-black-100">
                                <div className="flex gap-2 ">
                                    <strong>Payment Status:</strong>
                                    <Status
                                        status={payment.payment_status}
                                        amount={payment.outcome_amount}
                                        data={data}
                                        payment={payment}
                                        navigation={navigation}
                                    />
                                </div>
                                <h1 className="text-black-50">
                                    Pay the exact amount: $
                                    <span className="font-bold text-green-500">
                                        {payment.price_amount.toFixed(2)}
                                    </span>
                                    {payment.pay_currency !== "usdttrc20" && (
                                        <span className="font-bold text-green-500">
                                            {" or"} {payment.pay_amount.toFixed(4)}{" "}
                                            {payment.pay_currency}{" "}
                                        </span>
                                    )}
                                    to be able to complete the transaction. Make sure also that
                                    the address you're copying is exactly the same as provided for
                                    you to make payment to, if any problem contact us below.
                                </h1>

                                {(payment.payment_status === "confirmed" ||
                                    (payment.payment_status === "partially_paid" && payment.price_amount.toFixed(2) > 48.0) ||
                                    payment.payment_status === "finished") && (
                                        <div className="flex flex-col justify-center items-center">
                                            <h1 className="text-red-500 my-4 text-lg text-center">
                                                It's highly important to continue with the button below
                                                for your transaction to take effect on your account
                                                balance.
                                            </h1>

                                            <button
                                                to="/"
                                                className="flex mt-10 items-center gap-2 font-semibold rounded-full px-8 py-3 text-accent-gray-100 text-md bg-green-500 hover:bg-yellow-50 transition duration-150 ease-in-out w-max"
                                                onClick={async (e) => {

                                                    await SetADoc('transactionreceipt', email, {
                                                        amount: parseInt(payment.outcome_amount.toFixed()),
                                                        plan,
                                                        balance: 0,
                                                        counter: 0,
                                                        date: plan === "daily" ? moment().format("LLL") : moment().format("LL")
                                                    }).then((v) => {
                                                        navigation(mainhome, { replace: true });
                                                    });
                                                    return;
                                                }}
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                    {pay.length < 1 && (
                        <div className="flex justify-center">
                            <div
                                className="spinner-border animate-spin inline-block w-24 h-24 border-4 rounded-full text-black-100"
                                role="status"
                            >
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                </div>
                <YTVideos />
            </div>
        </div >
    );
};

export default PaymentGateway2;



const Status = ({ status, amount, data, navigation, payment }) => {
    if (status === "waiting") {
        return (
            <div className="flex gap-2">
                <h1 className="text-green-500">{status}</h1>
                <div
                    className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    if (status === "confirmed") {

        return (
            <div className="flex gap-2">
                <h1 className="text-green-100">{status}</h1>
                <div
                    className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    if (status === "finished") {

        return (
            <div className="flex gap-2">
                <h1 className="text-green-800">{status}</h1>
                <FaCheckCircle className="text-green-800" size={25} />
            </div>
        );
    }
    if (status === "failed") {
        return (
            <div className="flex gap-2">
                <h1 className="text-red-100">{status}</h1>
                <FaStopCircle className="text-red-100" size={25} />
            </div>
        );
    }
    if (status === "expired") {
        return (
            <div className="flex gap-2">
                <h1 className="text-red-900">{status}</h1>
                <FaStopCircle className="text-red-900" size={25} />
            </div>
        );
    }
    if (status === "confirming") {
        return (
            <div className="flex gap-2">
                <h1 className="text-orange-600">{status}</h1>
                <FaEllipsisH className="text-orange-600" size={25} />
            </div>
        );
    }
    if (status === "sending") {
        return (
            <div className="flex gap-2">
                <h1 className="text-green-600">{status}</h1>
                <FaEllipsisH className="text-green-600" size={25} />
            </div>
        );
    }
    if (status === "partially_paid") {


        return (
            <div className="flex gap-2">
                <h1 className="text-red-900">
                    {"Payment not fully made $"} {amount} only
                </h1>
                <FaQuestionCircle className="text-red-900" size={25} />
            </div>
        );
    }
};



///complaint id: X000273745
///Transaction id: 0000006922405136