import { useLayoutEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import '../react-confirm-alert.css';
import { ConversionRate, GetIPAddress, Info, Progress, onClosePopup, waitawhile } from '../config/functions';
import { cryptostopup, mainhome, paymentshop2, paymentshop3 } from '../config/data';
import { SetADoc, UID } from '../config/db';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useContextProvider } from '../config/context';


export const CreatePayment = async (list) => {
    const pkey = process.env.REACT_APP_CPAY
    //localStorage.setItem('pkey', pkey)
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", pkey);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "price_amount": list.amt,
        "price_currency": "usd",
        "pay_currency": list.coin,
        "ipn_callback_url": "https://nowpayments.io",
        "order_id": 'FBT-' + UID(8).toUpperCase(),
        "is_fee_paid_by_user": false,
        "is_fixed_rate": true,
        "order_description": `This window is the overview of your payment process, make sure you pay exactly the amount inicated above to avoid any transaction difficulties.`
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };


    return await fetch("https://api.nowpayments.io/v1/payment", requestOptions)
    /* .then(response => response.text())
    .then(result => {
        var data = JSON.parse(result)
        console.log(data)
    })
    .catch(error => console.log('error', error)); */
}

/*  const CreateInvoice = async (list) => {
    const pkey = process.env.REACT_APP_CPAY
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", pkey);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "price_amount": list.amount,
        "price_currency": "usd",
        "order_id": 'FBT3-' + UID(8).toUpperCase(),
        "order_description": 'This window is the overview of your transaction, make sure you pay exactly the amount inicated to avoid any transaction difficulties and errors.',
        "ipn_callback_url": "https://nowpayments.io",
        "success_url": "https://nowpayments.io",
        "partially_paid_url": "https://nowpayments.io",
        "cancel_url": "https://nowpayments.io"
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return await fetch("https://api.nowpayments.io/v1/invoice", requestOptions)
        .then(response => response.text())
        .then(result => {
            return JSON.parse(result)
        })
        .catch(error => console.log('error', error));
} */

export const GetPaymentStatus = async (pid) => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", process.env.REACT_APP_CPAY);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return await fetch(`https://api.nowpayments.io/v1/payment/${pid}`, requestOptions)
    /* .then(response => response.text())
     .then(result => console.log(JSON.parse(result)))
     .catch(error => console.log('error', error)); */

}


export const Paymentinitdialog = (list) => {

    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        closeOnClickOutside: true,
        closeOnEscape: true,
        customUI: ({ title, onClose }) => {
            return (
                <MainElem list={list} close={onClose} />
            )
        }
    });
}
const MainElem = ({ list, close }) => {

    const [idx, setidx] = useState(0);
    const [crtname, setcrtname] = useState("Credit Card, Bank Services, MoMo...");
    const [coin, setcoin] = useState('bnk')
    const [currency, setcurrency] = useState(1)
    const [currencysymb, setcurrencysymb] = useState("USD")
    const [loading, setloading] = useState(false)


    //const { onClosePopup } = useContextProvider();

    useLayoutEffect(() => {

        const x = setInterval(() => {
            setloading(true)
            GetIPAddress()
                .then(async (value) => {
                    const curr = value.currency
                    await ConversionRate(curr)
                        .then((result) => {
                            result = result === "" ? 1 : parseFloat(result)

                            let conv = result < 1.0 ? result + 1.0 : result
                            if (conv > 0) {
                                setloading(false)
                                setcurrency(conv)
                                setcurrencysymb(curr)
                                clearInterval(x)
                            }
                        }).catch((err) => {
                            setloading(false)

                        })
                }).catch((err) => {
                    setloading(false)

                })

        }, 1000)
    }, [])

    const configPayment = () => {
        const initamt = JSON.parse(localStorage.getItem("curr")).rate ?? currency
        const initcurr = JSON.parse(localStorage.getItem("curr")).currency ?? currencysymb
        const config = {
            public_key: process.env.REACT_APP_MJ_FWPK,
            tx_ref: Date.now(),
            amount: list.amt * initamt,
            currency: initcurr,
            payment_options: 'card, mobilemoney, ussd, banktransfer, qr, bankaccount',
            customer: {
                email: 'currentuser@gmail.com',
                phone_number: '',
                name: '',
            },
            customizations: {
                title: 'PackedTools',
                description: 'Payment for user subscription',
                logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
            },
        };

        return config
    }



    /* const [amount, setAmount] = useState(50);
    const amountref = useRef() */

    const handle = useFlutterwave(configPayment())


    const handlePayment = () => {
        handle({
            callback: async (response) => {

                if (response.status === "successful") {
                    const amountPaid = parseFloat(response.amount);

                    if (amountPaid === list.amt) {
                        // Handle full payment
                        console.log("Full payment received:", response);
                        // Update the backend, notify the user, etc.
                        await SetADoc('transactionreceipt', list.email, {
                            amount: amountPaid,
                            plan: list.plan,
                            balance: 0,
                            counter: 0,
                            date: list.plan === "daily" ? moment().format("LLL") : moment().format("LL")
                        }).then((v) => {
                            closePaymentModal() // this will close the modal programmatically
                            list.nav(mainhome, { replace: true });
                        });
                    } else {
                        Info({ title: "Payment insufficient", msg: "Partial payment received. Expected: " + list.amt + " Received: " + amountPaid + " Top up before you continue using this service" });
                    }

                } else {
                    Info({ title: "Payment Unsuccessful", msg: "There was an error trying to make your payment, try again later" });
                }
            },
            onClose: () => { },
        });


    }
    return (

        <div className='mx-8'>
            <div className='mx-auto py-4 px-8 max-w-xl rounded-lg bg-gray-800 w-full m-6 lg:w-max lg:h-max' style={{ zIndex: '99999' }}>
                <div className="">
                    <h1 className="font-extrabold text-xl mt-4 text-white">
                        {list.title}
                    </h1>
                    <div className="my-2">
                        {list.body}
                        {list.body === null && (<div className="flex flex-col gab-4">
                            <p className="text-sm my-4 text-yellow-50">
                                {list.desc}
                            </p>
                            {/* <div className="relative flex items-center">
                            <input
                                className="rounded-lg border-1 border-accent-light-100 bg-accent-gray-100 bg-opacity-10 py-2 pl-12 text-2xl font-bold pr-8 placeholder:text-accent-light-100 text-black outline-none focus:border-1 focus:border-yellow-100 w-full"
                                placeholder="Enter amount here"
                                type="number"
                                required
                                ref={amountref}
                                onChange={(e) => {
                                    setAmount(e.target.value);
                                }}
                                value={amount}
                            ></input>
                            <FaDollarSign className="absolute left-4 top-3" size={24} color="#6D6E72" />
                        </div> */}
                        </div>)}


                    </div>
                    <div className="flex relative gap-2 my-2 justify-center mt-4 border-1 border-accent-gray-100 py-12 rounded-lg">
                        {cryptostopup().map((value, index) => (
                            <div
                                className={`rounded-full p-2 ${idx === index ? "bg-green-500" : "bg-gray-100 bg-opacity-30 "
                                    } flex items-center justify-center cursor-pointer`}
                                key={index}
                                onClick={() => {
                                    setidx(index);
                                    setcoin(value.symb.toLowerCase());
                                    setcrtname(value.coin);
                                }}
                            >
                                <img src={value.icon} alt={value.coin} className="h-8 w-8" />
                            </div>
                        ))}
                        <h1 className="absolute text-center text-white mt-2 mb-2 bottom-0">
                            {crtname}
                        </h1>
                    </div>
                </div>
                <div className="flex gap-4 mt-8 justify-end">
                    <button className="p-2 hover:bg-accent-gray-100 bg-opacity-30 rounded-lg text-red-500" onClick={() => close()}>Cancel</button>
                    <button className="p-2 hover:bg-accent-gray-100 bg-opacity-30 rounded-lg text-green-500" onClick={() => {

                        onClosePopup()
                        //Progress({ msg: "Please wait whiles initializing payment process.", ref: list.ref })

                        if (coin === "bnk") {
                            //list.nav(paymentshop3, { state: { email: list.email, bal: list.bal, plan: list.plan } })

                           handlePayment()
                        }

                        else {

                            CreatePayment({ amt: list.bal, coin, fees: false })
                                .then((value) => value.text())
                                .then((value) => {
                                    var data = JSON.parse(value)
                                    list.nav(paymentshop2, { state: { code: data.payment_id, email: list.email, bal: list.bal, plan: list.plan } })
                                }).catch((err) => {
                                    Info({ title: "Process Error", msg: err.message })
                                })
                        }

                    }}>Continue</button>
                </div>
            </div >
        </div>

    );

}

export const Paymentstatusdialog = (list) => {
    confirmAlert({
        title: "Confirm Dialog",
        overlayClassName: "overlay-custom-className-name",
        closeOnClickOutside: false,
        closeOnEscape: false,
        customUI: ({ title, onClose }) => {
            return (
                <div className='mx-auto p-8 max-w-xl rounded-lg  w-5/6 m-6 lg:w-max lg:h-max' style={{ zIndex: '99999' }}>

                </div>
            )
        }
    });
}


