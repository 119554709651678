import React from "react";

import { Helmet } from "react-helmet";
const PageNotFound = () => {
  return (
    <div className="flex w-full flex-col justify-center items-center md:w-5/6 lg:w-3/6 my-auto h-screen">
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="%PUBLIC_URL%/logo1.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Signup or login to the altimate one stop shop for all your hacking tools; cryptocurrency, bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp"
        />
        <meta
          name="keyword"
          content="cryptocurrency, bank logs, bank cheque, carding, spamming, bank, account, hacking, vpn, socks5, rdp"
        />
        <link rel="canonical" href="http://packedtools.com/***" />
        <titie>Page Not Fount | 404</titie>
      </Helmet>

      <h1 className="text-8xl text-red-700 text-center font-extrabold">404</h1>
      <p className="text-lg text-white text-center">
        Page Not Found. Please check back later
      </p>
    </div>
  );
};

export default PageNotFound;
