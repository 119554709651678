import React from "react";



const AboutComponent = () => {
  return (
    <div className="flex flex-col justify-center text-gray-300 bg-black px-4 md:px-2 lg:px-0">
           <div className="flex my-8">
        <h1 className="text-sm md:text-lg text-white leading-10 text-justify">
          Welcome to our virtual store, where we offer a wide range of products
          and services to meet all of your hacking and cybercrime needs. Our
          inventory includes RDP, non-verified credit cards, bank logs, hacking
          tutorials, shopping scripts, bank abount details and social media
          growth services and a lot more. We also provice the opportunity to
          sell your ditgal coods which complince iwith our terms and conditions.
          We pride ourselves on offering top-quality products and services at
          competitive prices, and we're committed to helping our customers
          achieve their goals. We understand that the world of cybercrime can be
          complex and confusing, which is why we offer comprehensive tutorials
          and support to help our customers make the most of our products and
          services. Whether you're a seasoned hacker or just getting started, we
          have the tools and expertise you need to succeed. We source all of our
          products and services from trusted suppliers and ensure that they meet
          the highest standards of quality and reliability. We also stay
          up-to-date with the latest developments in the world of cybercrime, so
          you can be sure that you're always getting the most cutting-edge
          products and services available. At our virtual store, we take
          customer satisfaction seriously. We offer a range of payment and
          delivery options to suit your needs, and we're always here to answer
          any questions you may have. Our goal is to provide a seamless and
          stress-free shopping experience, so you can focus on achieving your
          goals in the world of cybercrime. So if you're looking for a reliable
          source for all your hacking and cybercrime needs, look no further than
          our virtual store. We're here to help you achieve your goals and
          succeed in the world of cybercrime.
        </h1>
      </div>
    </div>
  );
};

export default AboutComponent;
