import React, { useEffect, useState } from "react";
import { GetADoc, SetADoc, auth } from "../config/db";
import { NavLink, useNavigate } from "react-router-dom";
import { mainhome, userscollection } from "../config/data";
import Progressdialog from "./progres";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { IC } from "../temp/invitation";
import moment from "moment/moment";

const AuthComponent = () => {
  const [isSignup, setIsSignup] = useState(false);

  return (
    <div className="flex flex-col justify-center text-gray-300 bg-black px-8 md:px-2 lg:px-0">
      <NavLink className="nav-link mb-20" to={mainhome}>
        <h1 className="text-green-400 text-5xl font-bold text-center ">
          Packed<span className="text-white font-thin">Tools</span>
        </h1>
      </NavLink>
      {!isSignup && <Login setValue={setIsSignup} />}
      {isSignup && <Signup setValue={setIsSignup} />}
    </div>
  );
};

const reset = (setError) => {
  setTimeout(() => {
    setError("");
  }, 5000);
};

const Login = ({ setValue }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState("");
  const [count, setCount] = useState(0);
  const navigate = useNavigate();


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      reset(setError);
      setProgress(true);
      await signInWithEmailAndPassword(auth, email, password).then((v) => {
        GetADoc(userscollection, v.user.uid).then((value) => {
          setProgress(false);
          localStorage.setItem("user", JSON.stringify([value.data()]));
          navigate(mainhome, { replace: true });
          setCount((prev) => prev + 1);
        });
      });
    } catch (error) {
      console.error(JSON.stringify(error));
      setProgress(false);
      setError(error.code);
    }
  };

  return (
    <form className="mx-auto max-w-sm">
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="email"
        >
          Email
        </label>
        <input
          className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
      </div>
      <div className="">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="password"
        >
          Password
        </label>
        <input
          className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-100 mb-3 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="********"
        />
      </div>
      <div className=" mb-3">
        <label
          className="block text-red-500 text-sm font-bold mb-2"
          htmlFor="password"
        >
          {error}
        </label>
      </div>

      <div className="flex items-center justify-between">
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleLogin}
        >
          Sign In
        </button>
        <Progressdialog isProgress={progress} />
      </div>
      <div className=" my-5">
        <label className="block text-gray-500 text-sm mb-2" htmlFor="password">
          I don't have account{" "}
          <span
            className="text-green-500 hover:cursor-pointer "
            onClick={() => setValue(true)}
          >
            Let me create account
          </span>
        </label>
      </div>
    </form>
  );
};

const Signup = ({ setValue }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [code, setCode] = useState("");
  const [referer, setReferer] = useState("");
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const currentUrl = window.location.search;
    const urlSearchParams = new URLSearchParams(currentUrl);
    const params = Object.fromEntries(urlSearchParams.entries());
    setQueryParams(params);

    try {
      if (queryParams !== null) {
        setCode(queryParams.inv.toString());
        setReferer(queryParams.uid.toString());
      }
    } catch (e) {}
  }, [queryParams]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      reset(setError);
      if (password !== cpassword) {
        setError("Passwords do not macth");
        return;
      }
      if (code === "") {
        setError("You need an inviation code to proceed");
        return;
      }
   
    } catch (error) {
      console.error(JSON.stringify(error));
      setProgress(false);
      setError(error.code);
    }
  };

  return (
    <form className="mx-auto max-w-sm">
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="email"
        >
          Email
        </label>
        <input
          className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
      </div>
      <div className="">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="password"
        >
          Password
        </label>
        <input
          className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-100 mb-3 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="********"
        />
      </div>
      <div className="">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="cpassword"
        >
          Confirm Password
        </label>
        <input
          className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-100 mb-3 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
          id="cpassword"
          type="password"
          value={cpassword}
          onChange={(e) => setCpassword(e.target.value)}
          placeholder="********"
        />
      </div>
      <div className="">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="code"
        >
          Invitation code
        </label>
        <input
          className="shadow appearance-none  rounded w-full py-2 px-3 text-gray-100 mb-3 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
          id="code"
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter your invitation code"
        />
      </div>
      <div className=" mb-3">
        <label className="block text-red-500 text-sm font-bold mb-2">
          {error}
        </label>
      </div>

      <div className="flex items-center justify-between">
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleLogin}
        >
          Sign Up
        </button>
        <Progressdialog isProgress={progress} />
      </div>
      <div className=" my-5">
        <label className="block text-gray-500 text-sm mb-2" htmlFor="password">
          I already have account{" "}
          <span
            className="text-green-500 hover:cursor-pointer "
            onClick={() => setValue(false)}
          >
            send me to login page
          </span>
        </label>
      </div>
    </form>
  );
};

const signCreateAccount = async (
  email,
  password,
  progress,
  navigate,
  referer,
  error
) => {
  progress(true);
  await createUserWithEmailAndPassword(auth, email, password)
    .then((v) => {
      const invitationcode = IC[Math.floor(Math.random() * IC.length)];
      const user = {
        username: email.split("@")[0],
        invitationcode,
        email,
        password,
        createdat: moment().format("LLL"),
        balance: 0.0,
        invitedby: referer,
        uid: v.user.uid,
        referal: `https://packedtools.tools/userauthenticationpage?uid=${v.user.uid}$inv=${invitationcode}`,
      };
     
      SetADoc(userscollection, v.user.uid, user)
        .then((v) => {
          localStorage.setItem("user", JSON.stringify([user]));
          progress(false);

          navigate(mainhome, { replace: true });
        })
        .catch((err) => {
          progress(false);
          error(err.code);
        });
      progress(false);
    })
    .catch((err) => {
      progress(false);
      error(err.code);
    });
};

export default AuthComponent;
