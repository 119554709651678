import React from "react";

import AboutComponent from "../components/about";
import { Helmet } from "react-helmet";

const AboutPage = () => {
 
  return (
    <div className="w-full md:w-5/6 lg:w-5/6 xl:w-4/6">
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="%PUBLIC_URL%/logo1.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="This website offers many online transaction tools to make money, for security, fun, entertainment and many more. One stop shop for all your hacking tools; cryptocurrency, bank logs, bank cheque, carding, spamming, bank accounts, vpn, socks5, etc, rdp, cctobtc at reasonable prices"
        />
        <meta
          name="keyword"
          content="cryptocurrency, bank logs, bank cheque, carding, spamming, bank account hacking, vpn, socks5, rdp"
        />
        <link rel="canonical" href="http://packedtools.com/aboutpackedtools" />
      </Helmet>
    
      <div className="mt-28">
        <AboutComponent />
      </div>
    </div>
  );
};

export default AboutPage;
